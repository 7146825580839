<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Profil</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Profil</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Modifier mon profil</h5>
                                <form class="form-material" @submit.prevent="update">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Nom
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="seller.last_name" v-validate="'required'" name="Nom"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Nom') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Prenoms
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="seller.givens_name" v-validate="'required'" name="Prenoms" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Prenoms') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Contact(s)
                                                </label>
                                                <div class="col-sm-12">
                                                    <the-mask class="form-control" masked="true" type="tel" v-validate="'required'" name="Contact" v-model="seller.phone_numbers" :mask="['(+###) ## ## ## ## ## / (+###) ## ## ## ## ##']" placeholder="ex : (225) 07 00 00 00 00 / (225) 05 00 00 00 00" />
                                                    <span class="text-danger">{{ errors.first('Contact') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Email
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="seller.email" v-validate="'required|email'" name="Email" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Email') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Ancien Mot de passe
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="password"  v-model="seller.password" v-validate="'min:6'" name="AncienMotDePasse" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('AncienMotDePasse') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Nouveau Mot de passe
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="password"  v-model="seller.newPassword" v-validate="'min:6'" name="NouveauMotDePasse" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('NouveauMotDePasse') }}</span>
                                            </div>
                                        </div>
                                    </div>  
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            seller:{},
        };
    },
    mounted: function() {
      this.getUser()
  },
    methods: {
        getUser: function() {
                axios.get(URL_API+"auth/user",{
                     headers: {
                                Authorization: "Bearer " + this.$store.state.token,
                            },
                    })
                    .then((response) => {
                        console.log(response.data)
                        this.seller = response.data
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        },
        update: function() {
             this.$validator.validate().then(valid => {
                if (valid) {
                    axios.post(URL_API+"auth/update-user",this.seller,{
                        headers: {
                                Authorization: "Bearer " + this.$store.state.token,
                            },
                    })
                    .then((response) => {
                        console.log(response.data)
                        
                        Swal.fire({
                            position: 'top-end',
                            title:'Modification effectuée!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        this.$router.go(-1)
                        
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
            
        },
    },
  
}
</script>