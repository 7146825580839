<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor text-uppercase">Détail</h4>
                </div>
                <div class="col-md-7 align-self-center text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active">Détail</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Information detailléé</h4>
                        <div class="vtabs customvtab">
                            
                            <div v-if="loading">
                                <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                            </div>
                            <div v-else class="tab-content">
                                <div class="tab-pane active" id="home3" role="tabpanel">
                                    <div class="p-20">
                                        <form class="form-material" @submit.prevent="edit">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Nom
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text" id="example-text" v-model="client.last_name"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Prenoms
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text" id="example-text" v-model="client.givens_name"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Email
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text" id="example-text" v-model="client.email"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Contact
                                                        </label>
                                                        <div class="col-md-12">
                                                            <the-mask class="form-control" masked="true" type="tel" v-model="client.phone_numbers" :mask="['(+###) ## ## ## ## ## / (+###) ## ## ## ## ##']" placeholder="ex : (225) 07 00 00 00 00 / (225) 05 00 00 00 00" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"> 
                                                <button type="submit" class="btn btn-info">Modifier</button>
                                                <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                            </div>
                                        </form> 
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
export default {
    data: function() {
        return {
            client:{},
            loading:false
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getClient()
    },
    methods: {
        getClient: function() {
            let app = this
            this.loading = true
                axios.get(URL_API+"client/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.client = response.data
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false
                    });
        },
        edit: function() {
            this.$router.push('/edit-client/'+this.$route.params.id)
        },
    },
  
}
</script>