<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor">Détail</h4>
                </div>
                <div class="col-md-7 align-self-center text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active">Détail ville</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Information détaillée</h4>
                        <div v-if="loading">   
                            <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                        </div>
                        <div v-else-if="City" class="vtabs customvtab">
                            <ul class="nav nav-tabs tabs-vertical" role="tablist">
                                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#home3" role="tab"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Information</span> </a> </li>
                                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#profile3" role="tab"><span class="hidden-sm-up"><i class="ti-user"></i></span> <span class="hidden-xs-down">Ecole</span></a> </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane active" id="home3" role="tabpanel">
                                    <div class="p-20">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <form class="form-material" @submit.prevent="edit">
                                                            <div class="form-group">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label class="col-md-12" for="example-text">Nom
                                                                        </label>
                                                                        <div class="col-md-12">
                                                                            <input type="text" id="example-text" v-model="City.name"  class="form-control" placeholder="ecrire ici ..." readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label class="col-md-12" for="example-text"> Pays
                                                                        </label>
                                                                        <div class="col-md-12">
                                                                            <input type="text" id="example-text" v-model="City.country.name"  class="form-control" placeholder="ecrire ici ..." readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <button type="submit" class="btn btn-info">Modifier</button>
                                                                <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane  p-20" id="profile3" role="tabpanel">
                                    <div v-if="City.school.length > 0 " class="table-responsive">
                                        <table id="myTable" class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Libelle</th>
                                                    <th>Matiere</th>
                                                    <th>Niveau</th>
                                                    <th>Année</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(info,index) in City.school" :key="index">
                                                    <td>{{index + 1}}</td>
                                                    <td> {{info.code}} </td>
                                                    <td> {{info.name}} </td>
                                                    <td> {{info.type}} </td>
                                                    <td> {{info.location}} </td>
                                                    <td>
                                                        <button type="button" class="btn btn-info" @click="$router.push('/school-show/'+info.id)"> <i class="ti-eye"></i></button>
                                                        <button type="button" class="btn btn-warning" @click="$router.push('/school-edit/'+info.id)"> <i class="ti-pencil"></i></button>
                                                        <button type="button" class="btn btn-danger" @click="deleteExamen(info.id)"> <i class="ti-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else class="alert alert-primary" role="alert">
                                        Aucune école enregistrée
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
export default {
    data: function() {
        return {
            City:{},
            countries:[],
            loading: false
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getCity()
        this.getCountries()
    },
    methods: {
        getCity: function() {
                let app = this
                this.loading = true
                axios.get(URL_API+'city/'+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.City = response.data
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false
                    });
        },
        getCountries: function() {
            let app = this
                axios.get(URL_API+"countries")
                    .then((response) => {
                        console.log(response.data)
                        app.countries = response.data
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        },
        edit: function() {
            this.$router.push('/city-edit/'+this.$route.params.id)
        },
    },
  
}
</script>