<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor">Détail de matière</h4>
                </div>
                <div class="col-md-7 align-self-center text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active">Détail de matière</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-uppercase">Détail de matière</h5>
                            <div v-if="loading">   
                                <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                            </div>
                            <form v-else class="form-material" @submit.prevent="edit">
                                <div class="form-group">
                                    <div class="row ">
                                        <div class="col-md-6">
                                            <label class="col-md-12" for="example-text">Nom
                                            </label>
                                            <div class="col-md-6">
                                                <input type="text" id="example-text" v-model="subject.label"  class="form-control" placeholder="ecrire ici ..." readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="col-md-12" for="example-text">slug
                                            </label>
                                            <div class="col-md-6">
                                                <input type="text" id="example-text" v-model="subject.slug"  class="form-control" placeholder="ecrire ici ..." readonly>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="col-md-12" for="example-text">Niveau
                                            </label>
                                            <div class="col-md-12">
                                                <input type="text" id="example-text" v-model="subject.level.label"  class="form-control" placeholder="ecrire ici ..." readonly>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">
                                        <h3>Cours</h3>
                                        <div v-if="subject.courses.length > 0 " class="table-responsive">
                                            <table id="myTable" class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Libelle</th>
                                                        <th>Type de contenu</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(course,index) in subject.courses" :key="index">
                                                        <td>{{index + 1}}</td>
                                                        <td> {{course.label}} </td>
                                                        <td> {{course.type}} </td>
                                                        <td>
                                                            <button type="button" class="btn btn-info" @click="$router.push('/show-course/'+course.id)"> <i class="ti-eye"></i></button>
                                                            <button type="button" class="btn btn-warning" @click="$router.push('/edit-course/'+course.id)"> <i class="ti-pencil"></i></button>
                                                            <button type="button" class="btn btn-danger" @click="deleteCourse(course.id)"> <i class="ti-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-else class="alert alert-primary" role="alert">
                                            Aucun cours enregistré
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-info">Modifier</button>
                                    <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
export default {
    data: function() {
        return {
            loading:false,
            subject:{},
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getSubject()
    },
    methods: {
        getSubject: function() {
            this.loading = true
            let app = this
                axios.get(URL_API+"subject/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.subject = response.data
                        this.loading = false
                    })
                    .catch((error) => {
                        this.loading = false
                        console.log(error);
                    });
        },
        edit: function() {
            this.$router.push('/edit-subject/'+this.$route.params.id)
        },
    },
  
}
</script>