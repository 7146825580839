<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Ajouter un produit</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Ajouter un produit</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Ajouter un produit</h5>
                                <form class="form-material" @submit.prevent="store">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Code
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="product.code" v-validate="'required|alpha_num'" name="code"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('code') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Titre
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-validate="'required'" name="Titre" v-model="product.label"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Titre') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Prix
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="number"  v-validate="'required|integer'" name="Prix" v-model="product.price"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Prix') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Niveau
                                                </label>
                                                <div class="col-sm-12">
                                                    <select v-validate="'required'" name="Niveau" v-model="product.level" class="form-control" id="">
                                                        <option value="sixieme">Sixième</option>
                                                        <option value="cinquieme">Cinquième</option>
                                                        <option value="quatrieme">Quatrième</option>
                                                        <option value="troisieme">Troisième</option>
                                                        <option value="seconde">Seconde</option>
                                                        <option value="premiere">Première</option>
                                                        <option value="terminale">Terminale</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('Niveau') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Bradeur
                                                </label>
                                                <div class="col-md-12">
                                                    <select class="form-control" v-validate="'required|integer'" name="Bradeur" v-model="product.seller_id"  id="">
                                                        <option v-for="(seller,index) in sellers" :key="index" :value="seller.id"> {{seller.last_name}} {{seller.givens_name}} </option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('Bradeur') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Type de produit
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required|integer'" name="Type" v-model="product.type_id"  id="">
                                                        <option v-for="(productType,index) in productTypes" :key="index" :value="productType.id"> {{productType.label}} </option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('Type') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">photos
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="file" v-validate="'required'" name="Photo" ref="photo"  multiple>
                                                    <span class="text-danger">{{ errors.first('Photo') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            product:{},
            sellers:[],
            productTypes:[]
        };
    },
    mounted: function() {
        this.getSeller()
        this.getProductType()
    },
    methods: {
        getSeller: function() {
            let app = this
            this.loading = true
            axios.get(URL_API+"sellers")
            .then((response) => {
                app.sellers = response.data
                this.loading = false
            })
            .catch((error) => {
                console.log(error);
                this.loading = false
            });
        },
        getProductType: function() {
            let app = this
            this.loading = true
            axios.get(URL_API+"product-types")
            .then((response) => {
                app.productTypes = response.data
                this.loading = false
            })
            .catch((error) => {
                console.log(error);
                this.loading = false
            });
        },
        store: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    let product = new FormData();
                    product.append('code', this.product.code)
                    product.append('label', this.product.label)
                    product.append('level', this.product.level)
                    product.append('price', this.product.price)
                    product.append('seller_id', this.product.seller_id)
                    product.append('type_id', this.product.type_id)
                    
                    for( var i = 0; i < this.$refs.photo.files.length; i++ ){
                        let file = this.$refs.photo.files[i];
                        product.append('photo[' + i + ']', file);
                    }

                    axios.post(URL_API+"product",product)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Enregistrement effectué!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/products')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
        },
    },
  
}
</script>