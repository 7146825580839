<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Modifier un utilisateur</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Modifier un utilisateur</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Modifier un utilisateur</h5>
                                <form class="form-material" @submit.prevent="update">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Nom
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="user.last_name" v-validate="'required'" name="Nom"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Nom') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Prenom(s)
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="user.givens_name" v-validate="'required'" name="Prenoms" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Prenoms') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Contact(s)
                                                </label>
                                                <div class="col-sm-12">
                                                    <the-mask class="form-control" v-validate="'required'" name="Contact" masked="true" type="tel"  v-model="user.phone_numbers" :mask="['(+###) ## ## ## ## ## / (+###)) ## ## ## ## ##']" placeholder="ex : (225) 07 00 00 00 00 / (225) 05 00 00 00 00" />
                                                </div>
                                                <span class="text-danger">{{ errors.first('Contact') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Email
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="user.email" v-validate="'required|email'" name="Email" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Email') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Mot de passe
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="user.password" v-validate="'min:6'" name="MotDePasse"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('MotDePasse') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div  class="form-group" v-if="user.is_admin == '0' ">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Fonction
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="user.function" v-validate="'required'" name="Fonction" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Fonction') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Lieu d'habitation
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text" v-validate="'required'" name="Localisation" v-model="user.location"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Localisation') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            user:{},
        };
    },
    components: {
        //
    },
    mounted: function() {
        this.getUser()
    },
    methods: {
        getUser: function() {
            let app = this
                axios.get(URL_API+"user/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.user = response.data
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        },
        update: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    axios.put(URL_API+"user/"+this.$route.params.id,this.user)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Modification effectuée!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/users')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
                    
        },
    },
}
</script>