<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Modifier un livre</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Modifier un livre</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Modifier un  livre</h5>
                                <div v-if="loading">   
                                    <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                </div>
                                <form v-else class="form-material" @submit.prevent="update">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="col-md-12" for="example-text">Libellé
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text" id="example-text" v-validate="'required'" name="Libelle"  v-model="productType.label"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Libelle') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
export default {
    data: function() {
        return {
            productType:{},
            loading: false
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getCountrie()
    },
    methods: {
        getCountrie: function() {
            let app = this
            this.loading = true
                axios.get(URL_API+"product-type/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.productType = response.data
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false
                    });
        },
        update: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    axios.put(URL_API+"product-type/"+this.$route.params.id,this.productType)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Modification effectuée!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/product-types')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
                    
        },
    },
}
</script>