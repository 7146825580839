<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Détail</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Détail</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Détail</h5>
                                <div v-if="loading">
                                    <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                </div>
                                <form v-else class="form-material" @submit.prevent="edit">
                                    <div class="form-group">
                                        <div class="row ">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Nom
                                                </label>
                                                <div class="col-md-6">
                                                    <input type="text" id="example-text" v-model="level.label"  class="form-control" placeholder="ecrire ici ..." readonly>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Modifier</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import { VueLoading } from 'vue-loading-template'
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            level:{},
            loading:false
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getLevel()
    },
    methods: {
        getLevel: function() {
            this.loading= true
            let app = this
                axios.get(URL_API+"order-state/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.level = response.data
                        this.loading= false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading= false
                    });
        },
        edit: function() {
            this.$router.push('/edit-order-state/'+this.$route.params.id)
        },
    },
  
}
</script>