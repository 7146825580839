import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from "./router";
import axios from 'axios';
import store from "./store";
import fullCalendar from 'vue-fullcalendar'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueTheMask from 'vue-the-mask'
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);
Vue.use(VueTheMask)
Vue.use( CKEditor );


Vue.component('full-calendar', fullCalendar);
Vue.use(axios);

Vue.use(VueRouter);

import Default from "./layout/Default.vue";
import Order from "./layout/Order.vue";

Vue.component('default',Default);
Vue.component('order',Order);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
