<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor text-uppercase">{{product.nom}}</h4>
                </div>
                <div class="col-md-7 align-self-center text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active">{{product.name}}</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Information detaillée</h4>
                        <div class="vtabs customvtab">
                            <ul class="nav nav-tabs tabs-vertical" role="tablist">
                                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#home3" role="tab"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Information</span> </a> </li>
                                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#profile3" role="tab"><span class="hidden-sm-up"><i class="ti-image"></i></span> <span class="hidden-xs-down">Photos</span></a> </li>
                            </ul>
                            <div v-if="loading">
                                <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                            </div>
                            <div v-else class="tab-content">
                                <div class="tab-pane active" id="home3" role="tabpanel">
                                    <div class="p-20">
                                        <form class="form-material" @submit.prevent="edit">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Code
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text"  v-model="product.code"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Titre
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text"  v-model="product.label"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Niveau
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text"  v-model="product.level"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Prix
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text"  v-model="product.price"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Type de produit
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text"  v-model="product.type.label"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"> 
                                                <button type="submit" class="btn btn-info">Modifier</button>
                                                <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                            </div>
                                        </form> 
                                    </div>
                                </div>
                                <div class="tab-pane p-20 row" id="profile3" role="tabpanel">
                                   <div v-for="(productPhoto,index) in productPhotos"  :key="index" >
                                       <img :src="'http://localhost:8000/'+productPhoto" class="m-2" width="300" alt="">
                                        <button class="btn btn-danger" @click="deleteImage(product.id,productPhoto)"><i class="ti-trash"></i></button>
                                   </div>
                                   <div>
                                       <label> Ajouter une image</label>
                                       <div>
                                           <input type="file" ref="photo" v-validate="'length:'+taille+''" name="Photo" multiple>
                                       </div><br>
                                       <span class="text-danger">{{ errors.first('Photo')}}</span>
                                       <div>
                                            <button class="btn btn-info" @click="addImage(product.id)">Enregistrer</button>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
export default {
    data: function() {
        return {
            product:{},
            productPhotos:[],
            loading:false,
            taille:0
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getProduct()
    },
    methods: {
        getProduct: function() {
                let app = this
                this.loading = true
                axios.get(URL_API+"product/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.product = response.data
                        app.product.fullname = response.data.seller.last_name+' '+response.data.seller.givens_name
                        var tof = response.data.photo.split(';')
                        tof.pop()
                        this.productPhotos = tof
                        this.taille = 3 - this.productPhotos.length
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false
                    });
        },
        edit: function() {
            this.$router.push('/edit-seller-product/'+this.$route.params.id)
        },
        deleteImage: function(id,path){
            // let app = this
            Swal.fire({
            title: 'Etes-vous sur vouloir supprimer?',
            text: "Cette action est irreversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Supprimer!',
            cancelButtonText: 'Annuler!'
            }).then((result) => {
                if (result.isConfirmed) {
                   axios.put(URL_API+"delete-image/"+id,{
                       path: path
                   })
                    .then((response) => {
                        Swal.fire({
                            position: 'top-end',
                            title:'Suppression effectuée!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                        })
                        this.getProduct()
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            })
            
        },
        addImage: function(id){
            this.$validator.validate().then(valid => {
                if (valid) {
                    let product = new FormData();
                    product.append('id', id)
                    for( var i = 0; i < this.$refs.photo.files.length; i++ ){
                        let file = this.$refs.photo.files[i];
                        product.append('photo[' + i + ']', file);
                    }
                    axios.post(URL_API+"add-image/",product)
                    .then((response) => {
                        Swal.fire({
                            position: 'top-end',
                            title:'Suppression effectuée!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                        })
                        this.getProduct()
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
        }
    },
  
}
</script>