<template>
     <section id="wrapper">
        <div class="login-register" style="background-image:url(/assets/images/background/login-register.jpg);">
            <div class="login-box card">
                <div class="card-body">
                    <form class="form-horizontal form-material" id="loginform" @submit.prevent="onSubmit">
                        <h3 class="text-center m-b-20">Sign In</h3>
                        <div class="form-group ">
                            <div class="col-xs-12">
                                <input class="form-control" v-model="user.email" type="text" required="" placeholder="Username"> </div>
                        </div>
                        <div class="form-group">
                            <div class="col-xs-12">
                                <input class="form-control"  v-model="user.password" type="password" required="" placeholder="Password"> 
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <div class="d-flex no-block align-items-center">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Remember me</label>
                                    </div> 
                                    <div class="ml-auto">
                                        <a href="javascript:void(0)" id="to-recover" class="text-muted"><i class="fas fa-lock m-r-5"></i> Forgot pwd?</a> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <div class="col-xs-12 p-b-20">
                                <button class="btn btn-block btn-lg btn-info btn-rounded" type="submit">Log In</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: "Login",
  data: function() {
    return {
      user: {
        email: null,
        password: null,
      },
      error: false,
      isLoading: false,
      status: null,
      showPassword: false,
    };
  },
  components: {
    //
  },
  mounted: function() {

  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.$store.dispatch("login", this.user)
        .then(() => {          
              if (
                document.referrer.indexOf(
                  location.protocol + "//" + location.host
                ) === 0
              ) {
                window.location.href = document.referrer;
              } else {
                window.location.href =
                  "/";
              }
            
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          
        });
    },
  },
};
</script>