<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Cours</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Cours</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Modifier un cours</h5> <br>
                                <div v-if="loading">   
                                    <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                </div>
                                <form v-else class="form-material" @submit.prevent="store">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12 " for="example-text">Libellé
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="example-text" v-validate="'required'" name="libelle" v-model="information.label"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('libelle') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Niveau
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required'" name="niveau" v-model="information.level_id" @change="getSubject(information.level_id)" required >
                                                        <option></option>
                                                        <option v-for="(level,index) in levels" :key="index" :value="level.id"> {{level.label}} </option>
                                                    </select>
                                                </div>
                                                <span class="text-danger">{{ errors.first('niveau') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Matière
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required'" name="matière" v-model="information.subject_id" required >
                                                        <option v-for="(subject,index) in subjects" :key="index" :value="subject.id">{{subject.label}}</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('matière') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">
                                                    type 
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required'" name="fichier" v-model="information.type" required >
                                                        <option value="file">Fichier</option>
                                                        <option value="video">Video</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('fichier') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div  class="col-md-12" v-if="information.type == 'video'">
                                                <label class="col-md-12" for="example-text">Lien youtube
                                                </label>
                                                <div class="col-md-6"  >
                                                    <input class="form-control" v-validate="'required'" name="lien" type="text" v-model="information.link"  >
                                                </div>
                                                <span class="text-danger">{{ errors.first('lien') }}</span>
                                                <div class="col-md-6"  >
                                                    <iframe width="420" height="315"
                                                        :src="'https://www.youtube.com/embed/'+information.link">
                                                    </iframe>
                                                </div>
                                            </div>
                                            <div  class="col-md-12" v-if="information.type == 'file'">
                                                <label class="col-md-12" for="example-text">Document
                                                </label>
                                                <input type="file" ref="doc" v-validate="'required'" name="document" id="">
                                                <span class="text-danger">{{ errors.first('document') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { VueLoading } from 'vue-loading-template'
export default {
    data: function() {
        return {
            information:{
            },
            levels:{},
            subjects:{},
            schools:{},
            inforForms: [''],
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },
            loading: false
           
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getLevels();
        this.getCourse();
    },
    methods: {
        getCourse: function() {
                    let app = this
                    this.loading = true
                    axios.get(URL_API+"course/"+this.$route.params.id)
                            .then((response) => {
                                console.log(response.data)
                                app.information = response.data
                                app.getSubject(app.information.subject_id)
                                this.loading = false
                            })
                            .catch((error) => {
                                console.log(error);
                                this.loading = false
                            });
        },
        getLevels: function() {
            let app = this
                    axios.get(URL_API+"levels")
                            .then((response) => {
                                console.log(response.data)
                                app.levels = response.data
                            })
                            .catch((error) => {
                                console.log(error);
                            });
        },
        getSubject: function(id) {
            let app = this
                    axios.get(URL_API+"level-subject/"+id)
                            .then((response) => {
                                console.log(response.data)
                                app.subjects = response.data
                                app.information.level_id=app.subjects[0].level_id
                            })
                            .catch((error) => {
                                console.log(error);
                            });
        },
        store: function() {
            
            this.$validator.validate().then(valid => {
                if (valid) {
                        let app = this
                        let examen = new FormData();
                        examen.append('id', this.$route.params.id)
                        examen.append('label', this.information.label)
                        examen.append('subject_id', this.information.subject_id)
                        examen.append('type', this.information.type)
                        examen.append('link', this.information.link)
                        
                        if (this.$refs.doc) {
                            this.information.document = this.$refs.doc.files[0];
                            examen.append('document', this.information.document)
                        }
                

                    axios.post(URL_API+"update-course",examen)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Modification effectué!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/courses')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
                
        },

    },
  
}
</script>