<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Actualité</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Détail Actualité</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Détail Actualité</h5> <br>
                                <div v-if="loading">
                                    <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                </div>
                                <form  v-else class="form-material" @submit.prevent="edit">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12 " for="example-text">Libellé
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="example-text" v-model="information.label"  class="form-control" placeholder="ecrire ici ..." disabled>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Ecole
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-model="information.school_id" required disabled>
                                                        <option></option>
                                                        <option v-for="(patrimoine,index) in schooles" :key="index" :value="patrimoine.id"> {{patrimoine.name}} </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Type d'information
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-model="information.type" required disabled>
                                                        <option > </option>
                                                        <option value="text">Texte</option>
                                                        <option value="video">video</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6" v-if="!information.school_id">
                                                <label class="col-md-12" for="example-text"> Lieu (autre)
                                                </label>
                                                <div class="col-sm-12">
                                                    <input class="form-control" type="text" v-model="information.autor"  disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div v-if="information.type == 'text'" class="col-md-12">
                                                <label class="col-md-12" for="example-text">Valeur
                                                </label>
                                                <div class="col-md-12"  >
                                                    <div id="app" style="">
                                                        <ckeditor :editor="editor" v-model="information.value" :config="editorConfig" :disabled="editorDisabled"></ckeditor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="information.type == 'video'" class="col-md-12">
                                                <label class="col-md-12" for="example-text">Valeur
                                                </label>
                                                <div class="col-md-12"  >
                                                    <iframe width="420" height="315"
                                                        :src="'https://www.youtube.com/embed/'+information.link">
                                                    </iframe>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Modifier</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data: function() {
        return {
            information:{
                school_id: this.$route.params.id
            },
            schooles:{},
            schools:{},
            inforForms: [''],
            editorDisabled: true,
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },
            loading: false
           
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getSchool();
        this.getInformation();
    },
    methods: {
        edit: function(){
            this.$router.push('/edit-actuality/'+this.$route.params.id)
        },
        getSchool: function() {
            let app = this
                    axios.get(URL_API+"schools")
                            .then((response) => {
                                console.log(response.data)
                                app.schooles = response.data
                                app.addGuestRow(); 
                            })
                            .catch((error) => {
                                console.log(error);
                            });
        },
        getInformation: function() {
                let app = this
                this.loading= true
                axios.get(URL_API+"other-information/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.information = response.data
                        this.loading= false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading= false
                    });
        },
        addGuestRow: function() {
            this.inforForms.push('');
        },
        deleteGuestRow: function(key) {
         this.inforForms.splice(key, 1);
        },

    },
  
}
</script>