<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Modifier une Ecole</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Modifier une Ecole</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Modifier une Information</h5>
                                <form class="form-material" @submit.prevent="update">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Code
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text" v-validate="'required'" name="code" v-model="school.code"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('code') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Nom
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text" v-validate="'required'" name="nom" v-model="school.name"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('nom') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Type
                                                </label>
                                                <div class="col-sm-12">
                                                    <select v-validate="'required'" name="type" class="form-control" v-model="school.type">
                                                        <option value="privé">Privé</option>
                                                        <option value="semi-privé">Semi-privé</option>
                                                        <option value="publique">Publique</option>
                                                    </select>
                                                </div>
                                                <span class="text-danger">{{ errors.first('type') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Localisation
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text" v-validate="'required'" name="localisation" v-model="school.location"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('localisation') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Contact(s)
                                                </label>
                                                <div class="col-sm-12">
                                                    <the-mask class="form-control" v-validate="'required'" name="contact" masked="true" type="tel"  v-model="school.phone_numbers" :mask="['(+###) ## ## ## ## ## / (+###) ## ## ## ## ##']" placeholder="ex : (225) 07 00 00 00 00 / (225) 05 00 00 00 00" />
                                                </div>
                                                <span class="text-danger">{{ errors.first('contact') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Email
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text" v-validate="'required|email'" name="email" v-model="school.email"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('email') }}</span>
                                            </div>
                                        </div>
                                    </div>  
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Ville
                                                </label>
                                                <div class="col-sm-12">
                                                    <select v-validate="'required'" name="ville" v-model="school.city_id" class="form-control" required>
                                                        <option v-for="(CitySelect,index) in Citys" :key="index" :value="CitySelect.id"> {{CitySelect.name}} </option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('ville') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">date de creation
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="date" v-validate="'required'" name="date" v-model="school.date_creation"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('date') }}</span>
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Site web
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="school.website"  class="form-control" placeholder="www.monsite.domaine" >
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            school:{},
            Citys:null
        };
    },
    components: {
        //
    },
    mounted: function() {
        this.getSchool()
        this.getCity()
    },
    methods: {
        getSchool: function() {
            let app = this
                axios.get(URL_API+"school/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.school = response.data
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        },
        getCity: function() {
            let app = this
                    axios.get(URL_API+"cities")
                            .then((response) => {
                                console.log(response.data)
                                app.Citys = response.data
                            })
                            .catch((error) => {
                                console.log(error);
                            });
        },
        update: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    axios.put(URL_API+"school/"+this.$route.params.id,this.school)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Enregistrement effectué!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/schools')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
        },
    },
}
</script>