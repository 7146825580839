<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor text-uppercase">{{School.nom}}</h4>
                </div>
                <div class="col-md-7 align-self-center text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active">{{School.name}}</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Information detailléé</h4>
                        <div class="vtabs customvtab">
                            <ul class="nav nav-tabs tabs-vertical" role="tablist">
                                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#home3" role="tab"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">information</span> </a> </li>
                                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#profile3" role="tab"><span class="hidden-sm-up"><i class="ti-user"></i></span> <span class="hidden-xs-down">information connexe</span></a> </li>
                                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#messages3" role="tab"><span class="hidden-sm-up"><i class="ti-email"></i></span> <span class="hidden-xs-down">Classe</span></a> </li>
                                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#messages4" role="tab"><span class="hidden-sm-up"><i class="ti-email"></i></span> <span class="hidden-xs-down">Examens</span></a> </li>
                                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#admission-rate" role="tab"><span class="hidden-sm-up"><i class="ti-email"></i></span> <span class="hidden-xs-down">Performances</span></a> </li>
                            </ul>
                            <div v-if="loading">
                                <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                            </div>
                            <div v-else class="tab-content">
                                <div class="tab-pane active" id="home3" role="tabpanel">
                                    <div class="p-20">
                                        <form class="form-material" @submit.prevent="edit">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Code
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text" id="example-text" v-model="School.code"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Nom
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text" id="example-text" v-model="School.name"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Email
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text" id="example-text" v-model="School.email"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Lieu
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text" id="example-text" v-model="School.location"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Contact
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text"  id="example-text" v-model="School.phone_numbers"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Site web
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text" id="example-text" v-model="School.website"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">Type
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="" id="example-text" v-model="School.type"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-md-12" for="example-text">date de creation
                                                        </label>
                                                        <div class="col-md-12">
                                                            <input type="text" id="example-text" v-model="School.date_creation"  class="form-control" placeholder="" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"> 
                                                <button type="submit" class="btn btn-info">Modifier</button>
                                                <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                            </div>
                                        </form> 
                                        
                                    </div>
                                </div>
                                <div class="tab-pane  p-20" id="profile3" role="tabpanel">
                                    <h3>Informations sur l'ecole</h3>
                                    <div v-if="School.school_informations.length > 0" class="table-responsive">
                                        <table id="myTable" class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Libelle</th>
                                                    <th>Valeur</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(info,index) in School.school_informations" :key="index">
                                                    <td>{{index + 1}}</td>
                                                    <td> {{info.label}} </td>
                                                    <td> {{info.value}} </td>
                                                    <td>
                                                        <button type="button" class="btn btn-info" @click="$router.push('/school-informations-show/'+info.id)"> <i class="ti-eye"></i></button>
                                                        <button type="button" class="btn btn-warning" @click="$router.push('/school-informations-edit/'+info.id)"> <i class="ti-pencil"></i></button>
                                                        <button type="button" class="btn btn-danger" @click="deleteSchool(info.id)"> <i class="ti-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else class="alert alert-primary" role="alert">
                                        Aucune information
                                    </div>
                                    <div class="form-group"> 
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.push('/school-informations/'+School.id)">Ajouter une information</button>
                                    </div>
                                </div>
                                <div class="tab-pane p-20" id="messages3" role="tabpanel">
                                    <h3>Classe de l'ecole</h3>
                                    <div v-if="School.school_informations.length > 0" class="table-responsive">
                                        <table id="myTable" class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Libelle</th>
                                                    <th>Scolarité</th>
                                                    <th>Nombre de classe</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(info,index) in School.school_level" :key="index">
                                                    <td>{{index + 1}}</td>
                                                    <td> {{info.label}} </td>
                                                    <td> {{info.pivot.tuition}} </td>
                                                    <td> {{info.pivot.class_nb}} </td>
                                                    <td>
                                                        <button type="button" class="btn btn-info" @click="$router.push('/school-level-show/'+info.id)"> <i class="ti-eye"></i></button>
                                                        <button type="button" class="btn btn-warning" @click="$router.push('/school-level-edit/'+info.id)"> <i class="ti-pencil"></i></button>
                                                        <button type="button" class="btn btn-danger" @click="deleteLevel(info.id)"> <i class="ti-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else class="alert alert-primary" role="alert">
                                        Aucune classe enregistré
                                    </div>
                                    <div class="form-group"> 
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.push('/create-level-for-school/'+School.id)">Ajouter une classe</button>
                                    </div>
                                </div>
                                <div class="tab-pane p-20" id="messages4" role="tabpanel">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div v-if="School.examens.length > 0 " class="table-responsive">
                                                <table id="myTable" class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Libelle</th>
                                                            <th>Matiere</th>
                                                            <th>Niveau</th>
                                                            <th>Année</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(info,index) in School.examens" :key="index">
                                                            <td>{{index + 1}}</td>
                                                            <td> {{info.label}} </td>
                                                            <td> {{info.subject}} </td>
                                                            <td> {{info.level}} </td>
                                                            <td> {{info.year}} </td>
                                                            <td>
                                                                <button type="button" class="btn btn-info" @click="$router.push('/show-examen-blanc/'+info.id)"> <i class="ti-eye"></i></button>
                                                                <button type="button" class="btn btn-warning" @click="$router.push('/edit-examen-blanc/'+info.id)"> <i class="ti-pencil"></i></button>
                                                                <button type="button" class="btn btn-danger" @click="deleteExamen(info.id)"> <i class="ti-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div v-else class="alert alert-primary" role="alert">
                                                Aucun examen enregistré
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group"> 
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.push('/create-level-for-school/'+School.id)">Ajouter une classe</button>
                                    </div>
                                </div>
                                <div class="tab-pane p-20" id="admission-rate" role="tabpanel">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div v-if="School.examens.length > 0 " class="table-responsive">
                                                <table id="myTable" class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Libelle</th>
                                                            <th>Matiere</th>
                                                            <th>Niveau</th>
                                                            <th>Année</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(info,index) in School.examens" :key="index">
                                                            <td>{{index + 1}}</td>
                                                            <td> {{info.label}} </td>
                                                            <td> {{info.subject}} </td>
                                                            <td> {{info.level}} </td>
                                                            <td> {{info.year}} </td>
                                                            <td>
                                                                <button type="button" class="btn btn-info" @click="$router.push('/show-examen-blanc/'+info.id)"> <i class="ti-eye"></i></button>
                                                                <button type="button" class="btn btn-warning" @click="$router.push('/edit-examen-blanc/'+info.id)"> <i class="ti-pencil"></i></button>
                                                                <button type="button" class="btn btn-danger" @click="deleteExamen(info.id)"> <i class="ti-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div v-else class="alert alert-primary" role="alert">
                                                Aucun examen enregistré
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group"> 
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.push('/add-school-performance/'+School.id)">Ajouter une performance</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
export default {
    data: function() {
        return {
            School:{},
            loading:false
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getSchool()
    },
    methods: {
        getSchool: function() {
            let app = this
            this.loading = true
                axios.get(URL_API+"school/"+this.$route.params.id)
                    .then((response) => {
                        
                        response.data.performances.forEach(element => {
                            element.value = JSON.parse(element.value)
                        });
                        app.School = response.data
                        console.log(response.data)
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false
                    });
        },
        edit: function() {
            this.$router.push('/school-edit/'+this.$route.params.id)
        },
        Level: function() {
            let app = this
                axios.delete(URL_API+"school-information/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.getSchool()
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        },
        deleteLevel: function(id){
            axios.delete(URL_API+"school-level/"+id)
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
  
}
</script>