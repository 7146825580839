<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Modifier un produit</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Modifier un produit</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Modifier un produit</h5>
                                <form class="form-material" @submit.prevent="update">
                                    <div class="form-group">
                                        <div class="row">
                                            
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Titre
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text" v-validate="'required'" name="Titre" v-model="product.label"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Titre') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Description
                                                </label>
                                                <div class="col-md-12">
                                                    <textarea v-model="product.description" v-validate="''" name="Description" class="form-control" placeholder="ecrire ici ..."  cols="30" rows="10"></textarea>
                                                </div>
                                                <span class="text-danger">{{ errors.first('Description') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Type de produit
                                                </label>
                                                <div class="col-md-12">
                                                    <select class="form-control" v-validate="'required|integer'" name="Type" v-model="product.short_market_type_id"  id="">
                                                        <option v-for="(productType,index) in productTypes" :key="index" :value="productType.id"> {{productType.label}} </option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('Type') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Prix
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="product.price" v-validate="'required|integer'" name="Prix" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Prix') }}</span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Vendeur/Magasin
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text"  v-model="product.seller_name" v-validate="'required'" name="vendeur" class="form-control" placeholder="ecrire ici ..." >
                                                    <span class="text-danger">{{ errors.first('vendeur') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Lieu
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text"  v-model="product.seller_location" v-validate="'required'" name="lieu" class="form-control" placeholder="ecrire ici ..." >
                                                    <span class="text-danger">{{ errors.first('lieu') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Contacts
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text"  v-model="product.seller_phone_numbers" v-validate="'required'" name="contacts" class="form-control" placeholder="ecrire ici ..." >
                                                    <span class="text-danger">{{ errors.first('contacts') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            product:{},
            sellers:[],
            productTypes:[]
        };
    },
    components: {
        //
    },
    mounted: function() {
        this.getProduct()
        this.getProductType()
    },
    methods: {
        getProductType: function() {
            let app = this
            this.loading = true
            axios.get(URL_API+"short-market-types")
            .then((response) => {
                app.productTypes = response.data
                this.loading = false
            })
            .catch((error) => {
                console.log(error);
                this.loading = false
            });
        },
        getProduct: function() {
            let app = this
                axios.get(URL_API+"short-market/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.product = response.data
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        },
        update: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    axios.put(URL_API+"short-market/"+this.$route.params.id,this.product)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Modification effectuée!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/short-markets/')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
        },
    },
}
</script>