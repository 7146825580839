<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor">Commandes</h4>
                </div>
                <div class="col-md-7 align-self-center text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active">commandes</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Livres</h5>
                            <hr>
                            <div v-if="loading">   
                                <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                            </div>
                            <div v-else-if="products.length > 0" class="table-responsive">
                                <table id="myTable" class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Num commande</th>
                                            <th>Client</th>
                                            <th>Etat</th>
                                            <th>Date de comande</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(seller,index) in products" :key="index">
                                            <td> {{index + 1}}</td>
                                            <td> {{seller.order_number}} </td>
                                            <td> {{seller.client.last_name}} {{seller.client.givens_name}}</td>
                                            <td> {{seller.order_state.label}} </td>
                                            <td> {{seller.created_at}} </td>
                                            <td>
                                                <button type="button" class="btn btn-info" @click="$router.push('/show-order/'+seller.id)"> <i class="ti-eye"></i></button>
                                                <button type="button" class="btn btn-danger" @click="deleteOrder(seller.id)"> <i class="ti-trash"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else class="alert alert-primary text-center" role="alert">
                                Aucune commande
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
export default {
  data: function() {
    return {
      products:null,
      loading: false
    };
  },
  components: {
    VueLoading
  },
  mounted: function() {
      this.getOrders()
  },
    methods: {
        getOrders: function() {
            let app = this
            this.loading = true
                    axios.get(URL_API+"orders")
                            .then((response) => {
                                console.log(response.data)
                                app.products = response.data
                                this.loading = false
                            })
                            .catch((error) => {
                                console.log(error);
                                this.loading = false
                            });
        },
        deleteOrder: function(id){
            let app = this
            Swal.fire({
            title: 'Etes-vous sur vouloir supprimer?',
            text: "Cette action est irreversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Supprimer!',
            cancelButtonText: 'Annuler!'
            }).then((result) => {
                if (result.isConfirmed) {
                   axios.delete(URL_API+"order/"+id)
                    .then((response) => {
                        Swal.fire({
                            position: 'top-end',
                            title:'Suppression effectuée!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                        })
                        console.log(response.data)
                        app.getOrders()
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            })
            
        }
    },
  
}
</script>