<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Ajouter une ville</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Ajouter une ville</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Ajouter une Information</h5>
                                <form class="form-material" @submit.prevent="store">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Nom
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text" id="example-text" v-model="City.name" v-validate="'required|alpha'" name="libelle"  class="form-control" placeholder="ecrire ici ...">
                                                    <span class="text-danger">{{ errors.first('libelle') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Pays
                                                </label>
                                                <div class="col-md-12">
                                                    <select v-validate="'required|integer'" name="pays" class="form-control" id="" v-model="City.country_id" >
                                                        <option  v-for="(country,index) in countries" :key="index" :value="country.id"> {{country.name}} </option>
                                                    </select>
                                                </div>
                                                <span class="text-danger">{{ errors.first('pays') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
import { Validator } from 'vee-validate';

const dictionary = {
  en: {
    messages:{
      length: (a,b) => 'La longueur de la '+ a+' doit être de '+ b,
      integer: () => 'caractère numerique obligatoire',
      email: (a) => 'Le champs '+a+' doit etre un Email valide',
      alpha: () => 'caractère alphabetique obligatoire',
      min: (a,b) => 'Le champ du mot de passe doit contenir au moins '+ b +' caractères',
      alpha_spaces: () => 'Le champ du mot de passe doit contenir des caractères alphabetique et des espaces ',
      required: () => 'champs requis',
      alpha_num: (a) => 'Le champ '+a+' ne peut contenir que des caractères alphanumériques',
    }
  },
};

// Override and merge the dictionaries
Validator.localize(dictionary);

const validator = new Validator({ first_name: 'alpha' });

validator.localize('en');
export default {
    data: function() {
        return {
            City:{},
            countries:[]
        };
    },
    components: {
        //
    },
    mounted: function() {
        this.getCountries()
    },
    methods: {
        getCountries: function() {
            let app = this
                axios.get(URL_API+"countries")
                    .then((response) => {
                        console.log(response.data)
                        app.countries = response.data
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        },
        store: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    axios.post(URL_API+"city",this.City)
                        .then((response) => {
                            console.log(response.data)
                            Swal.fire({
                                position: 'top-end',
                                title:'Enregistrement effectué!',
                                showConfirmButton: false,
                                icon: 'success',
                                timer: 1500
                                }
                            )
                            app.$router.push('/cities')
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            });
            
        },
    },
  
}
</script>