<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Evaluation</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Evaluation</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Detail d'un Evaluation</h5> <br>
                                <div v-if="loading">   
                                    <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                </div>
                                <form v-else class="form-material" @submit.prevent="edit">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12 " for="example-text">Libellé
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="example-text" v-model="information.label"  class="form-control" placeholder="ecrire ici ..." disabled >
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Niveau
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-model="information.subject_id" @change="getSubject(information.level_id)" disabled >
                                                        <option></option>
                                                        <option v-for="(level,index) in levels" :key="index" :value="level.id"> {{level.label}} </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Matière
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-model="information.subject_id" disabled >
                                                        <option v-for="(subject,index) in subjects" :key="index" :value="subject.id">{{subject.label}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div  class="col-md-6">
                                                <label class="col-md-12" for="example-text">Sujet
                                                </label>
                                                <div class="col-md-12"  >
                                                    <a :href="URL+information.document">Voir le document</a>
                                                </div>
                                            </div>
                                            <div  class="col-md-6" >
                                                <label class="col-md-12" for="example-text">Corriger
                                                </label>
                                                <div class="col-md-12"  >
                                                    <a :href="URL+information.corrected">Voir le document</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Modifier</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import axios from "axios";
import { URL_API,URL} from "@/config";
import { VueLoading } from 'vue-loading-template'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data: function() {
        return {
            information:{
            },
            URL:URL,
            levels:{},
            subjects:{},
            schools:{},
            inforForms: [''],
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },
            loading: false
           
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getLevels();
        this.getEvaluation();
    },
    methods: {
        getEvaluation: function() {
            let app = this
            this.loading =  true
                    axios.get(URL_API+"evaluation/"+this.$route.params.id)
                            .then((response) => {
                                console.log(response.data)
                                app.information = response.data
                                app.getSubject(app.information.subject_id)
                                this.loading =  false
                            })
                            .catch((error) => {
                                this.loading =  false
                                console.log(error);
                            });
        },
        getLevels: function() {
            let app = this
                    axios.get(URL_API+"levels")
                            .then((response) => {
                                console.log(response.data)
                                app.levels = response.data
                            })
                            .catch((error) => {
                                console.log(error);
                            });
        },
        getSubject: function(id) {
            let app = this
                    axios.get(URL_API+"level-subject/"+id)
                            .then((response) => {
                                console.log(response.data)
                                app.subjects = response.data
                            })
                            .catch((error) => {
                                console.log(error);
                            });
        },
        edit: function() {
            this.$router.push('/edit-evaluation/'+this.$route.params.id)
        },

    },
  
}
</script>