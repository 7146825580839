<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Ajouter un Article</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Accueil</a></li>
                                <li class="breadcrumb-item active">Ajouter un Article</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Ajouter un Article</h5>
                                <form class="form-material" @submit.prevent="store">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Titre
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-validate="'required'" name="Titre" v-model="product.label"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Titre') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Prix
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="number"  v-validate="'required|integer'" name="Prix" v-model="product.price"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Prix') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Description
                                                </label>
                                                <div class="col-sm-12">
                                                    <textarea v-validate="''" name="description" v-model="product.description"  class="form-control" placeholder="ecrire ici ..."  cols="30" rows="10"></textarea>
                                                </div>
                                                <span class="text-danger">{{ errors.first('description') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Type de Article
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required|integer'" name="Type" v-model="product.type_id"  id="">
                                                        <option v-for="(productType,index) in productTypes" :key="index" :value="productType.id"> {{productType.label}} </option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('Type') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Vendeur/Magasin
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="product.seller_name" v-validate="'required'" name="nom_du_vendeur"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('nom_du_vendeur') }}</span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Lieu de vente
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="product.seller_location" v-validate="'required'" name="lieu_de_vente"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('lieu_de_vente') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Contact(s)
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="product.seller_phone_numbers" v-validate="'required'" name="contacts"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('contacts') }}</span>
                                            </div>
                                        </div>
                                    </div>  
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">photos
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="file" v-validate="'required'" name="Photo" ref="photo"  multiple>
                                                    <span class="text-danger">{{ errors.first('Photo') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            product:{},
            sellers:[],
            productTypes:[]
        };
    },
    mounted: function() {
        this.getProductType()
    },
    methods: {
        getProductType: function() {
            let app = this
            this.loading = true
            axios.get(URL_API+"short-market-types")
            .then((response) => {
                app.productTypes = response.data
                this.loading = false
            })
            .catch((error) => {
                console.log(error);
                this.loading = false
            });
        },
        store: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    let product = new FormData();
                    product.append('label', this.product.label)
                    product.append('description', this.product.description)
                    product.append('price', this.product.price)
                    product.append('seller_name', this.product.seller_name)
                    product.append('seller_location', this.product.seller_location)
                    product.append('seller_phone_numbers', this.product.seller_phone_numbers)
                    product.append('short_market_type_id', this.product.type_id)
                    
                    for( var i = 0; i < this.$refs.photo.files.length; i++ ){
                        let file = this.$refs.photo.files[i];
                        product.append('photo[' + i + ']', file);
                    }

                    axios.post(URL_API+"short-market",product)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Enregistrement effectué!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/short-markets')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
        },
    },
  
}
</script>