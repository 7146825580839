import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { URL_API} from "@/config";

Vue.use(Vuex);

export default new Vuex.Store({

    state:{
        isLoading: 0,
        
        isAdmin: localStorage.getItem("admin") || '',
        token: localStorage.getItem("token") || '',
        user: localStorage.getItem("user") || '',
    },

    getters:{
        isLoggedIn: state => !!state.token,
        authStatus: state => state.isLoading,
        isAdmin: state => !!state.isAdmin,
    },

    setters:{

    },

    mutations:{
        auth_request(state) {
            state.isLoading = 1;
        },
        auth_success(state, token, user) {
            state.isLoading = 0;
            state.token = token;
            state.user = user;
        },
        auth_admin(state, token, user) {
          state.isLoading = 0;
          state.token = token;
          state.user = user;
        },
        auth_error(state) {
            state.isLoading = 0;
        },
        logout_request(state) {
            state.isLoading = 1;
        },
        logout_success(state) {
            state.isLoading = 0;
            state.token = "";
            state.user = "";
        },
        logout_error(state) {
            state.isLoading = 0;
        },
    },

    actions:{
        login({ commit }, user_credentials) {
            return new Promise((resolve, reject) => {
              commit("auth_request");
              axios
                .post(
                   URL_API+"auth/login",
                  user_credentials
                )
                .then(resp => {
                    
                  if (resp.data.access_token) {   
                    const token = resp.data.access_token;
                    const user = resp.data.user;
                    if (resp.data.user.is_admin == 1 || resp.data.user.is_admin == '1') {
                      
                      localStorage.setItem("token", token);
                      localStorage.setItem("user", JSON.stringify(user));
                      localStorage.setItem("admin", token);
                      commit("auth_admin");
                      resolve(resp);
                    } else{
                      
                      localStorage.setItem("token", token);
                      localStorage.setItem("user", JSON.stringify(user));
                      localStorage.removeItem("admin");
                      commit("auth_success");
                      resolve(resp);
                    }
                    
                    
                  } else {
                    commit("auth_error");
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    reject(resp);
                  }
                })
                .catch(err => {
                  commit("auth_error");
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  reject(err);
                });
            });
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                
                commit("logout_request");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                commit("logout_success");
                const token = localStorage.getItem("token");
                resolve();
                if (token === 1) {
                  reject()
                }
            });
          },
    }
});