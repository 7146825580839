<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor">Ajouter un type de fourniture</h4>
                </div>
                <div class="col-md-7 align-self-center text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active">Ajouter un type de fourniture</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-uppercase">Ajouter une categorie de produits</h5>
                            <form class="form-material" @submit.prevent="store">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="col-md-12" for="example-text">Libellé
                                            </label>
                                            <div class="col-md-12">
                                                <input type="text" id="example-text" v-validate="'required'" name="Libelle" v-model="productType.label"  class="form-control" placeholder="ecrire ici ...">
                                            </div>
                                            <span class="text-danger">{{ errors.first('Libelle') }}</span>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="col-md-12" for="example-text">Description
                                            </label>
                                            <div class="col-md-12">
                                                <textarea v-validate="'required'" name="Description" v-model="productType.description"  class="form-control" placeholder="ecrire ici ..." id="" cols="30" rows="10"></textarea>
                                            </div>
                                            <span class="text-danger">{{ errors.first('Description') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-info">Enregistrer</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            productType:{}
        };
    },
    components: {
        //
    },
    mounted: function() {
        
    },
    methods: {
        store: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    axios.post(URL_API+"short-market-type",this.productType)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Enregistrement effectué!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/short-market-types')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
                    
        },
    },
  
}
</script>