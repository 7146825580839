<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Modifier un produit</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Modifier un produit</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Modifier un produit</h5>
                                <form class="form-material" @submit.prevent="update">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Code
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="product.code" v-validate="'required|alpha_num'" name="code" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('code') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Titre
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="product.label" v-validate="'required'" name="Titre" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Titre') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row"> 
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Niveau
                                                </label>
                                                <div class="col-sm-12">
                                                    <select v-validate="'required'" name="Niveau" v-model="product.level" class="form-control" id="">
                                                        <option value="sixieme">Sixième</option>
                                                        <option value="cinquieme">Cinquième</option>
                                                        <option value="quatrieme">Quatrième</option>
                                                        <option value="troisieme">Troisième</option>
                                                        <option value="seconde">Seconde</option>
                                                        <option value="premiere">Première</option>
                                                        <option value="terminale">Terminale</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('Niveau') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Prix
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text"  v-model="product.price" v-validate="'required|integer'" name="Prix" class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Prix') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Type de produit
                                                </label>
                                                <div class="col-md-12">
                                                    <select class="form-control" v-model="product.type_id" v-validate="'required|integer'" name="Type" id="">
                                                        <option v-for="(productType,index) in productTypes" :key="index" :value="productType.id"> {{productType.label}} </option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('Type') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            product:{},
            sellers:[],
            productTypes:[]
        };
    },
    components: {
        //
    },
    mounted: function() {
        this.getProduct()
        this.getSeller()
        this.getProductType()
    },
    methods: {
        getSeller: function() {
            let app = this
            this.loading = true
            axios.get(URL_API+"sellers")
            .then((response) => {
                app.sellers = response.data
                this.loading = false
            })
            .catch((error) => {
                console.log(error);
                this.loading = false
            });
        },
        getProductType: function() {
            let app = this
            this.loading = true
            axios.get(URL_API+"product-types")
            .then((response) => {
                app.productTypes = response.data
                this.loading = false
            })
            .catch((error) => {
                console.log(error);
                this.loading = false
            });
        },
        getProduct: function() {
            let app = this
                axios.get(URL_API+"product/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.product = response.data
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        },
        update: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    axios.put(URL_API+"seller-product/"+this.$route.params.id,this.product,
                        {
                        headers: {
                                    Authorization: "Bearer " + this.$store.state.token,
                                },
                        })
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Modification effectuée!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/seller-products/')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
                    
        },
    },
}
</script>