<template>
    <div>
        <header class="topbar">
            <nav class="navbar top-navbar navbar-expand-md navbar-light">
                <div class="navbar-header">
                    <a class="navbar-brand" href="/">
                        <b>
                            <img src="/assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
                            <img src="/assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />
                        </b>
                        <span class="hidden-xs"><span class="font-bold">Devoir de maison</span></span>
                    </a>
                </div>
                <div class="navbar-collapse">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                        <li class="nav-item"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0)"><i class="icon-menu"></i></a> </li>
                        <li class="nav-item">
                            <form class="app-search d-none d-md-block d-lg-block">
                                <input type="text" class="form-control" placeholder="Search & enter">
                            </form>
                        </li>
                    </ul>
                    <ul class="navbar-nav my-lg-0">
                       
                        <li class="nav-item dropdown u-pro">
                            <a class="nav-link dropdown-toggle waves-effect waves-dark profile-pic" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <span class="hidden-md-down"> {{user.givens_name}} {{user.last_name}} &nbsp;<i class="fa fa-angle-down"></i></span> </a>
                            <div class="dropdown-menu dropdown-menu-right animated flipInY">
                                <!-- text-->
                                <a href="javascript:void(0)" class="dropdown-item" @click="$router.push('/profil')"><i class="ti-user"></i> Mon Profil</a>
                                
                                <div class="dropdown-divider"></div>
                                <!-- text-->
                                <a  class="dropdown-item" @click="logout"><i class="fa fa-power-off"></i> Deconnexion</a>
                                <!-- text-->
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    </div>
</template>
<script>

export default {
    data: function(){
        return{
            user:JSON.parse(this.$store.state.user),
        }
    },

    methods:{
        logout: function() {
            this.$store.dispatch("logout").then(() => {
                window.location.href = "/login";
            });
        },
    }
}
</script>