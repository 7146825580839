<template>
    <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Cours</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Cours</li>
                            </ol>
                            <button type="button" class="btn btn-info d-none d-lg-block m-l-15" @click="$router.push('/create-course')"><i class="fa fa-plus-circle"></i>Ajouter un cours</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <h3>Cours</h3>
                                    <div v-if="loading">   
                                        <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                    </div>
                                    <div v-else-if="courses.length > 0 " class="table-responsive">
                                        <table id="myTable" class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Libelle</th>
                                                    <th>Matière</th>
                                                    <th>Niveau</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(course,index) in courses" :key="index">
                                                    <td>{{index + 1}}</td>
                                                    <td> {{course.label}} </td>
                                                    <td> {{course.subject.label}} </td>
                                                    <td> {{course.subject.level.label}} </td>
                                                    <td>
                                                        <button type="button" class="btn btn-info" @click="$router.push('/show-course/'+course.id)"> <i class="ti-eye"></i></button>
                                                        <button type="button" class="btn btn-warning" @click="$router.push('/edit-course/'+course.id)"> <i class="ti-pencil"></i></button>
                                                        <button type="button" class="btn btn-danger" @click="deleteCourse(course.id)"> <i class="ti-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else class="alert alert-primary" role="alert">
                                        Aucun cours enregistré
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import { VueLoading } from 'vue-loading-template'
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function(){
        return{
            courses:{},
            loading: false
        }
    },
    components: {
        VueLoading
    },
    mounted: function() {
      this.getCourse()
    },
    methods: {
        getCourse: function() {
            let app = this
            this.loading = true
            axios.get(URL_API+"courses")
            .then((response) => {
                console.log(response.data)
                app.courses = response.data
                this.loading = false
            })
            .catch((error) => {
                console.log(error);
                this.loading = false
            });
        },
        deleteCourse: function(id){

            Swal.fire({
            title: 'Etes-vous sur vouloir supprimer?',
            text: "Cette action est irreversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Supprimer!',
            cancelButtonText: 'Annuler!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(URL_API+"course/"+id)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                                    position: 'top-end',
                                    title:'Suppression effectué!',
                                    showConfirmButton: false,
                                    icon: 'success',
                                    timer: 1500
                                })
                        this.getCourse()
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                    
                }
            })
            
        }
    },
}
</script>