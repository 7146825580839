<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Evaluation</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Evaluation</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Enregistrer une évaluation</h5> <br>
                                <form class="form-material" @submit.prevent="store">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12 " for="example-text">Libellé
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="example-text" v-validate="'required'" name="libelle" v-model="information.label"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('libelle') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Niveau
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required'" name="niveau" v-model="information.level_id" @change="getSubject(information.level_id)" required >
                                                        <option></option>
                                                        <option v-for="(level,index) in levels" :key="index" :value="level.id"> {{level.label}} </option>
                                                    </select>
                                                </div>
                                                <span class="text-danger">{{ errors.first('niveau') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Matière
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required'" name="matière" v-model="information.subject_id" required >
                                                        <option v-for="(subject,index) in subjects" :key="index" :value="subject.id">{{subject.label}}</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('matière') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div  class="col-md-6">
                                                <label class="col-md-12" for="example-text">Sujet
                                                </label>
                                                <div class="col-md-12"  >
                                                    <input type="file" ref="doc" v-validate="'required'" name="document" id="">
                                                    <span class="text-danger">{{ errors.first('document') }}</span>
                                                </div>
                                            </div>
                                            <div  class="col-md-6" >
                                                <label class="col-md-12" for="example-text">Corrigé
                                                </label>
                                                <div class="col-md-12"  >
                                                    <input type="file" ref="doc" v-validate="'required'" name="corrigé" id="">
                                                    <span class="text-danger">{{ errors.first('corrigé') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data: function() {
        return {
            information:{
                school_id: this.$route.params.id
            },
            levels:{},
            subjects:{},
            schools:{},
            inforForms: [''],
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
            }
           
        };
    },
    components: {
    },
    mounted: function() {
        this.getLevel();
    },
    methods: {
        getLevel: function() {
            let app = this
                    axios.get(URL_API+"levels")
                            .then((response) => {
                                console.log(response.data)
                                app.levels = response.data
                            })
                            .catch((error) => {
                                console.log(error);
                            });
        },
        getSubject: function(id) {
            let app = this
                    axios.get(URL_API+"level-subject/"+id)
                            .then((response) => {
                                console.log(response.data)
                                app.subjects = response.data
                            })
                            .catch((error) => {
                                console.log(error);
                            });
        },
        store: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    let examen = new FormData();
                    examen.append('label', this.information.label)
                    examen.append('subject_id', this.information.subject_id)

                    if (this.$refs.doc) {
                        this.information.document = this.$refs.doc.files[0];
                        examen.append('document', this.information.document)
                    }
                    if (this.$refs.cor) {
                        this.information.corrected = this.$refs.cor.files[0];
                        examen.append('corrected', this.information.corrected)
                    }

                    axios.post(URL_API+"evaluation",examen)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Enregistrement effectué!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/evaluations')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
                
        },

    },
  
}
</script>