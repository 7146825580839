<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Détail pays</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">détail pays</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="vtabs customvtab">
                                                <ul class="nav nav-tabs tabs-vertical" role="tablist">
                                                    <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#home3" role="tab"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Information</span> </a> </li>
                                                    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#profile3" role="tab"><span class="hidden-sm-up"><i class="ti-user"></i></span> <span class="hidden-xs-down">Ecoles</span></a> </li>
                                                    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#messages3" role="tab"><span class="hidden-sm-up"><i class="ti-email"></i></span> <span class="hidden-xs-down">Examens</span></a> </li>
                                                </ul>
                                                <div v-if="loading">   
                                                    <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                                </div>
                                                <div v-else class="tab-content">
                                                    <div class="tab-pane active" id="home3" role="tabpanel">
                                                        <div class="p-20">
                                                            <form class="form-material" @submit.prevent="edit">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <label class="col-md-12" for="example-text">Nom
                                                                            </label>
                                                                            <div class="col-md-12">
                                                                                <input type="text" id="example-text" v-model="Countrie.name"  class="form-control" placeholder="ecrire ici ..." readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <label class="col-md-12" for="example-text">Code
                                                                            </label>
                                                                            <div class="col-md-12">
                                                                                <input type="text" class="form-control" v-model="Countrie.code" name="" id="" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <button type="submit" class="btn btn-info">Modifier</button>
                                                                    <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane  p-20" id="profile3" role="tabpanel">
                                                        <div class="table-responsive">
                                                            <table v-if="Countrie.school.length" id="myTable" class="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Libelle</th>
                                                                        <th>Matiere</th>
                                                                        <th>Niveau</th>
                                                                        <th>Année</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(info,index) in Countrie.school" :key="index">
                                                                        <td>{{index + 1}}</td>
                                                                        <td> {{info.code}} </td>
                                                                        <td> {{info.name}} </td>
                                                                        <td> {{info.type}} </td>
                                                                        <td> {{info.location}} </td>
                                                                        <td>
                                                                            <button type="button" class="btn btn-info" @click="$router.push('/school-show/'+info.id)"> <i class="ti-eye"></i></button>
                                                                            <button type="button" class="btn btn-warning" @click="$router.push('/school-edit/'+info.id)"> <i class="ti-pencil"></i></button>
                                                                            <button type="button" class="btn btn-danger" @click="deleteExamen(info.id)"> <i class="ti-trash"></i></button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div v-else class="alert alert-primary" role="alert">
                                                                Aucune école enregistré
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane p-20" id="messages3" role="tabpanel">
                                                        <div class="table-responsive">
                                                            <table v-if="Countrie.examens.length" id="myTable" class="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Libelle</th>
                                                                        <th>Matiere</th>
                                                                        <th>Niveau</th>
                                                                        <th>Année</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(info,index) in Countrie.examens" :key="index">
                                                                        <td>{{index + 1}}</td>
                                                                        <td> {{info.label}} </td>
                                                                        <td> {{info.subject}} </td>
                                                                        <td> {{info.level}} </td>
                                                                        <td> {{info.year}} </td>
                                                                        <td>
                                                                            <button type="button" class="btn btn-info" @click="$router.push('/show-examen/'+info.id)"> <i class="ti-eye"></i></button>
                                                                            <button type="button" class="btn btn-warning" @click="$router.push('/edit-examen/'+info.id)"> <i class="ti-pencil"></i></button>
                                                                            <button type="button" class="btn btn-danger" @click="deleteExamen(info.id)"> <i class="ti-trash"></i></button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div v-else class="alert alert-primary" role="alert">
                                                                Aucun examen enregistré
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
export default {
    data: function() {
        return {
            Countrie:{},
            loading: false
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getCountrie()
    },
    methods: {
        getCountrie: function() {
            let app = this
            this.loading= true
                axios.get(URL_API+"country/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.Countrie = response.data
                        this.loading= false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading= false
                    });
        },
        deleteExamen: function(id){
            axios.delete(URL_API+"examen/"+id)
                .then((response) => {
                    console.log(response.data)
                    this.getCountrie()
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        edit: function() {
            this.$router.push('/country-edit/'+this.$route.params.id)
        },
    },
  
}
</script>