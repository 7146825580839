<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Examen</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Modifier Examen</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Modifier Examen</h5> <br>
                                <div v-if="loading">   
                                    <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                </div>
                                <form v-else class="form-material" @submit.prevent="update">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12 " for="example-text">Libellé
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="example-text" v-validate="'required'" name="libelle" v-model="information.label"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('libelle') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Matiere
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="example-text" v-validate="'required'" name="libelle" v-model="information.subject"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('libelle') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Année
                                                </label>
                                                <div class="col-sm-12">
                                                        <input class="form-control" v-validate="'required'" name="Annee" v-mask="'####'" type="year" v-model="information.year"  >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Annee') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div  class="col-md-12">
                                                <label class="col-md-12" for="example-text">Matiere
                                                </label>
                                                <div class="col-md-12"  >
                                                    <select class="form-control" v-validate="'required'" name="matiere" v-model="information.examen_subject_id">
                                                        <option value=""></option>
                                                        <option v-for="(subject,index) in Subjects" :key="index" :value="subject.id"> {{subject.label}} </option>
                                                    </select>
                                                </div>
                                                <span class="text-danger">{{ errors.first('matiere') }}</span>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="col-md-12" for="example-text">Document
                                                </label>
                                                <input type="file" ref="doc" name="document">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { VueLoading } from 'vue-loading-template'
export default {
    data: function() {
        return {
            information:{
                school_id: this.$route.params.id
            },
            Subjects:[],
            schools:{},
            loading:false,
            inforForms: [''],
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
            }
           
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        // this.getLevel();
        this.getExamen();
    },
    methods: {
        getLevel: function() {
            let app = this
            this.loading = true
            axios.get(URL_API+"subject-by-level/"+app.information.examen_subject_id)
                    .then((response) => {
                        console.log(response.data)
                        app.Subjects = response.data
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false
                    });
        },
        getExamen: function() {
            let app = this
                axios.get(URL_API+"examen-topic/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.information = response.data
                        app.getLevel()
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        },
        update: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                        this.information.document = this.$refs.doc.files[0];
                        let app = this
                        let examen = new FormData();
                        examen.append('id', this.$route.params.id)
                        examen.append('label', this.information.label)
                        examen.append('subject', this.information.subject)
                        examen.append('year', this.information.year)
                        examen.append('document', this.information.document)

                        axios.post(URL_API+"update-examen",examen)
                            .then((response) => {
                                console.log(response.data)
                                Swal.fire({
                                    position: 'top-end',
                                    title:'Enregistrement effectué!',
                                    showConfirmButton: false,
                                    icon: 'success',
                                    timer: 1500
                                    }
                                )
                                app.$router.push('/examens')
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                }
            });
        }

    },
  
}
</script>