import VueRouter from "vue-router";

import store from "./store";

// User

import UserIndex from './views/User/index.vue'
import UserCreate from './views/User/create.vue'
import UserShow from './views/User/show.vue'
import UserEdit from './views/User/edit.vue'

// Course

import CourseIndex from './views/EDUCATION/Course/index.vue'
import CourseCreate from './views/EDUCATION/Course/create.vue'
import CourseShow from './views/EDUCATION/Course/show.vue'
import CourseEdit from './views/EDUCATION/Course/edit.vue'

// Evaluation

import EvaluationIndex from './views/EDUCATION/Evaluation/index.vue'
import EvaluationCreate from './views/EDUCATION/Evaluation/create.vue'
import EvaluationShow from './views/EDUCATION/Evaluation/show.vue'
import EvaluationEdit from './views/EDUCATION/Evaluation/edit.vue'

// Countrie
import CountrieIndex from './views/EDUCATION/Countrie/index.vue'
import CountrieCreate from './views/EDUCATION/Countrie/create.vue'
import CountrieShow from './views/EDUCATION/Countrie/show.vue'
import CountrieEdit from './views/EDUCATION/Countrie/edit.vue'

// City
import CityIndex from './views/EDUCATION/City/index.vue'
import CityCreate from './views/EDUCATION/City/create.vue'
import CityShow from './views/EDUCATION/City/show.vue'
import CityEdit from './views/EDUCATION/City/edit.vue'

// School
import SchoolIndex from './views/EDUCATION/School/index.vue'
import SchoolCreate from './views/EDUCATION/School/create.vue'
import SchoolShow from './views/EDUCATION/School/show.vue'
import SchoolEdit from './views/EDUCATION/School/edit.vue'
import SchoolPatrimoineAdd from './views/EDUCATION/School/addpatrimoine.vue'
import SchoolPatrimoineEdit from './views/EDUCATION/School/editInformation.vue'
import SchoolPatrimoineShow from './views/EDUCATION/School/showInformation.vue'

// SCHOOL PERFORMANCE
import SchoolPerformanceCreate from './views/EDUCATION/School/Performance/create.vue'

// Event Information
import ActualityIndex from './views/EDUCATION/SchoolInformation/index.vue'
import ActualityCreate from './views/EDUCATION/SchoolInformation/create.vue'
import ActualityEdit from './views/EDUCATION/SchoolInformation/edit.vue'
import ActualityShow from './views/EDUCATION/SchoolInformation/show.vue'

// Examens
import ExamenIndex from './views/EDUCATION/Examen/index.vue'
import ExamenCreate from './views/EDUCATION/Examen/create.vue'
import ExamenEdit from './views/EDUCATION/Examen/edit.vue'
import ExamenShow from './views/EDUCATION/Examen/show.vue'
import ExamenSubjectShow from './views/EDUCATION/Examen/subjectExamen.vue'
import ExamenTopicShow from './views/EDUCATION/Examen/sujetsExamen.vue'


// ExamenBlanc

import ExamenBlancIndex from './views/EDUCATION/ExamenBlanc/index.vue'
import ExamenBlancCreate from './views/EDUCATION/ExamenBlanc/create.vue'
import ExamenBlancEdit from './views/EDUCATION/ExamenBlanc/edit.vue'
import ExamenBlancShow from './views/EDUCATION/ExamenBlanc/show.vue'

// Niveau 
import LevelIndex from './views/EDUCATION/Level/index.vue'
import LevelCreate from './views/EDUCATION/Level/create.vue'
import LevelShow from './views/EDUCATION/Level/show.vue'
import LevelEdit from './views/EDUCATION/Level/edit.vue'

// Matiere 
import SubjectIndex from './views/EDUCATION/Subject/index.vue'
import SubjectCreate from './views/EDUCATION/Subject/create.vue'
import SubjectShow from './views/EDUCATION/Subject/show.vue'
import SubjectEdit from './views/EDUCATION/Subject/edit.vue'

////////////  SHOPPING ///////////////

// ORDER STATE

import OrderStateIndex from './views/SHOPPING/OrderState/index.vue'
import OrderStateCreate from './views/SHOPPING/OrderState/create.vue'
import OrderStateShow from './views/SHOPPING/OrderState/show.vue'
import OrderStateEdit from './views/SHOPPING/OrderState/edit.vue'

// PRODUCT TYPE

import ProductTypeIndex from './views/SHOPPING/ProductType/index.vue'
import ProductTypeCreate from './views/SHOPPING/ProductType/create.vue'
import ProductTypeShow from './views/SHOPPING/ProductType/show.vue'
import ProductTypeEdit from './views/SHOPPING/ProductType/edit.vue'

// SELLER

import SellerIndex from './views/SHOPPING/Seller/index.vue'
import SellerCreate from './views/SHOPPING/Seller/create.vue'
import SellerShow from './views/SHOPPING/Seller/show.vue'
import SellerEdit from './views/SHOPPING/Seller/edit.vue'

// PRODUCT

import ProductIndex from './views/SHOPPING/Product/index.vue'
import ProductCreate from './views/SHOPPING/Product/create.vue'
import ProductShow from './views/SHOPPING/Product/show.vue'
import ProductEdit from './views/SHOPPING/Product/edit.vue'

// CLIENT

import ClientIndex from './views/SHOPPING/Client/index.vue'
import ClientCreate from './views/SHOPPING/Client/create.vue'
import ClientShow from './views/SHOPPING/Client/show.vue'
import ClientEdit from './views/SHOPPING/Client/edit.vue'

// ORDER

import OrderIndex from './views/SHOPPING/Order/index.vue'
import OrderShow from './views/SHOPPING/Order/show.vue'

// Seller ORDER

import SellerOrderIndex from './views/SHOPPING/Seller/Order/index.vue'
import SellerOrderShow from './views/SHOPPING/Seller/Order/show.vue'

// PRODUCT

import SellerProductIndex from './views/SHOPPING/Seller/Product/index.vue'
import SellerProductCreate from './views/SHOPPING/Seller/Product/create.vue'
import SellerProductShow from './views/SHOPPING/Seller/Product/show.vue'
import SellerProductEdit from './views/SHOPPING/Seller/Product/edit.vue'

// Boutique TYPE

import ShortMarketTypeIndex from './views/SHOPPING/Boutique/Type/index.vue'
import ShortMarketTypeCreate from './views/SHOPPING/Boutique/Type/create.vue'
import ShortMarketTypeShow from './views/SHOPPING/Boutique/Type/show.vue'
import ShortMarketTypeEdit from './views/SHOPPING/Boutique/Type/edit.vue'
// Boutique TYPE

import ShortMarketIndex from './views/SHOPPING/Boutique/index.vue'
import ShortMarketCreate from './views/SHOPPING/Boutique/create.vue'
import ShortMarketShow from './views/SHOPPING/Boutique/show.vue'
import ShortMarketEdit from './views/SHOPPING/Boutique/edit.vue'

// HOME
import Home from "./views/home.vue";
import Login from './views/login.vue'
import Profil from './views/profil.vue'
import Welcome from "./views/home.vue";


const routes = [

  // Home
  {
    path: "/",
    name: "accueil",
    component: Home,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next("/welcome");
      }
    }
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome,
    meta: {
      layout: "order",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAdmin) {
        next();
        return;
      }else{
        next("/");
      }
    }
  },
  
  {
    path: "/profil",
    name: "profil",
    component: Profil,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next("/profil-seller");
      }
    }
  },
  {
    path: "/profil-seller",
    name: "profil",
    component: Profil,
    meta: {
      layout: "order",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAdmin) {
        next();
        return;
      }else{
        next("/profil");
      }
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "no-sidebar",
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next();
        return;
      }else{
        next("/");
      }
    }
  },
  //   USER
  {
    path: "/users",
    name: "Userindex",
    component: UserIndex,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/create-user",
    name: "Usercreate",
    component: UserCreate,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-user/:id",
    name: "Usershow",
    component: UserShow,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-user/:id",
    name: "Useredit",
    component: UserEdit,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
//   Course
  {
    path: "/courses",
    name: "Courseindex",
    component: CourseIndex,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/create-course",
    name: "Coursecreate",
    component: CourseCreate,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-course/:id",
    name: "Courseshow",
    component: CourseShow,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-course/:id",
    name: "Courseedit",
    component: CourseEdit,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },

  //   Evaluation
  {
    path: "/evaluations",
    name: "evaluationindex",
    component: EvaluationIndex,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/create-evaluation",
    name: "evaluationcreate",
    component: EvaluationCreate,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-evaluation/:id",
    name: "evaluationshow",
    component: EvaluationShow,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-evaluation/:id",
    name: "evaluationedit",
    component: EvaluationEdit,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },

  // Countrie

  {
    path: "/countries",
    name: "Countrieindex",
    component: CountrieIndex,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/add-country",
    name: "Countriecreate",
    component: CountrieCreate,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/country-show/:id",
    name: "CountrieShow",
    component: CountrieShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/country-edit/:id",
    name: "Countrieedit",
    component: CountrieEdit,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },

    // City

  {
    path: "/cities",
    name: "Cityindex",
    component: CityIndex,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/add-city",
    name: "Citycreate",
    component: CityCreate,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/city-show/:id",
    name: "CityShow",
    component: CityShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/city-edit/:id",
    name: "Cityedit",
    component: CityEdit,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },

      // School

  {
    path: "/schools",
    name: "Schoolindex",
    component: SchoolIndex,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/add-school",
    name: "Schoolcreate",
    component: SchoolCreate,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/school-show/:id",
    name: "SchoolShow",
    component: SchoolShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/school-edit/:id",
    name: "Schooledit",
    component: SchoolEdit,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/school-informations/:id",
    name: "SchoolpatrimoineAdd",
    component: SchoolPatrimoineAdd,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/school-informations-edit/:id",
    name: "SchoolpatrimoineEdit",
    component: SchoolPatrimoineEdit,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/add-school-performance/:id",
    name: "SchoolPerformanceCreate",
    component: SchoolPerformanceCreate,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/school-informations-show/:id",
    name: "SchoolpatrimoineShow",
    component: SchoolPatrimoineShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  
         // Actualité
    {
      path: "/actualities",
      name: "Actualityindex",
      component: ActualityIndex,
      meta: {
          layout: "default",
          requiredAuth: true
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdmin) {
          next();
          return;
        }else{
          next('/');
        }
      }
    },
    
  {
    path: "/create-actuality",
    name: "ActualityCreate",
    component: ActualityCreate,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-actuality/:id",
    name: "ActualityEdit",
    component: ActualityEdit,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-actuality/:id",
    name: "ActualityShow",
    component: ActualityShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
         // Examen
      {
        path: "/examens",
        name: "Examenindex",
        component: ExamenIndex,
        meta: {
            layout: "default",
            requiredAuth: true
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.isAdmin) {
            next();
            return;
          }else{
            next('/');
          }
        }
      },
      {
        path: "/create-examen",
        name: "ExamenCreate",
        component: ExamenCreate,
        meta: {
            layout: "default",
            requiredAuth: true
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.isAdmin) {
            next();
            return;
          }else{
            next('/');
          }
        }
      },
      {
        path: "/edit-examen/:id",
        name: "ExamenEdit",
        component: ExamenEdit,
        meta: {
            layout: "default",
            requiredAuth: true
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.isAdmin) {
            next();
            return;
          }else{
            next('/');
          }
        }
      },
      {
        path: "/show-examen/:id",
        name: "ExamenShow",
        component: ExamenShow,
        meta: {
            layout: "default",
            requiredAuth: true
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.isAdmin) {
            next();
            return;
          }else{
            next('/');
          }
        }
      },
      {
        path: "/show-subject-examen/:id",
        name: "ExamenSubjectShow",
        component: ExamenSubjectShow,
        meta: {
            layout: "default",
            requiredAuth: true
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.isAdmin) {
            next();
            return;
          }else{
            next('/');
          }
        }
      },
      {
        path: "/show-examen-topic/:id",
        name: "ExamenTopicShow",
        component: ExamenTopicShow,
        meta: {
            layout: "default",
            requiredAuth: true
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.isAdmin) {
            next();
            return;
          }else{
            next('/');
          }
        }
      },
      
           // Examen Blanc
           {
            path: "/examens-blanc",
            name: "ExamenBlancindex",
            component: ExamenBlancIndex,
            meta: {
                layout: "default",
                requiredAuth: true
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.isAdmin) {
                next();
                return;
              }else{
                next('/');
              }
            }
          },
        {
          path: "/create-examen-blanc",
          name: "ExamenBlancCreate",
          component: ExamenBlancCreate,
          meta: {
              layout: "default",
              requiredAuth: true
          },
          beforeEnter: (to, from, next) => {
            if (store.getters.isAdmin) {
              next();
              return;
            }else{
              next('/');
            }
          }
        },
        {
          path: "/edit-examen-blanc/:id",
          name: "ExamenBlancEdit",
          component: ExamenBlancEdit,
          meta: {
              layout: "default",
              requiredAuth: true
          },
          beforeEnter: (to, from, next) => {
            if (store.getters.isAdmin) {
              next();
              return;
            }else{
              next('/');
            }
          }
        },
        {
          path: "/show-examen-blanc/:id",
          name: "ExamenBlancShow",
          component: ExamenBlancShow,
          meta: {
              layout: "default",
              requiredAuth: true
          },
          beforeEnter: (to, from, next) => {
            if (store.getters.isAdmin) {
              next();
              return;
            }else{
              next('/');
            }
          }
        },
  
    // LEVEL

  {
    path: "/levels",
    name: "levelindex",
    component: LevelIndex,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/add-level",
    name: "levelcreate",
    component: LevelCreate,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-level/:id",
    name: "levelShow",
    component: LevelShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-level/:id",
    name: "leveledit",
    component: LevelEdit,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  }, 
    // Matiere

    {
      path: "/subjects",
      name: "Subjectindex",
      component: SubjectIndex,
      meta: {
          layout: "default",
          requiredAuth: true
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdmin) {
          next();
          return;
        }else{
          next('/');
        }
      }
    },
    {
      path: "/add-subject",
      name: "Subjectcreate",
      component: SubjectCreate,
      meta: {
          layout: "default",
          requiredAuth: true
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdmin) {
          next();
          return;
        }else{
          next('/');
        }
      }
    },
    {
      path: "/show-subject/:id",
      name: "SubjectShow",
      component: SubjectShow,
      meta: {
          layout: "default",
          requiredAuth: true
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdmin) {
          next();
          return;
        }else{
          next('/');
        }
      }
    },
    {
      path: "/edit-subject/:id",
      name: "Subjectedit",
      component: SubjectEdit,
      meta: {
          layout: "default",
          requiredAuth: true
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdmin) {
          next();
          return;
        }else{
          next('/');
        }
      }
    }, 
    /////////  SHOPPPING /////////

      // Product Type

  {
    path: "/product-types",
    name: "productTypeIndex",
    component: ProductTypeIndex,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/create-product-type",
    name: "ProductTypecreate",
    component: ProductTypeCreate,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-product-type/:id",
    name: "ProductTypeShow",
    component: ProductTypeShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-product-type/:id",
    name: "ProductTypeedit",
    component: ProductTypeEdit,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },

  // Sellers

  {
    path: "/sellers",
    name: "SellerIndex",
    component: SellerIndex,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/create-seller",
    name: "Sellercreate",
    component: SellerCreate,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-seller/:id",
    name: "SellerShow",
    component: SellerShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-seller/:id",
    name: "Selleredit",
    component: SellerEdit,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
// Products

  {
    path: "/products",
    name: "ProductIndex",
    component: ProductIndex,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/create-product",
    name: "Productcreate",
    component: ProductCreate,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-product/:id",
    name: "ProductShow",
    component: ProductShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-product/:id",
    name: "Productedit",
    component: ProductEdit,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },

  // Client

  {
    path: "/clients",
    name: "ClientIndex",
    component: ClientIndex,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/create-client",
    name: "Clientcreate",
    component: ClientCreate,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-client/:id",
    name: "ClientShow",
    component: ClientShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-client/:id",
    name: "Clientedit",
    component: ClientEdit,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  // Client

  {
    path: "/orders",
    name: "OrderIndex",
    component: OrderIndex,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-order/:id",
    name: "OrderShow",
    component: OrderShow,
    meta: {
        layout: "default",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/seller-orders/",
    name: "SellerOrderIndex",
    component: SellerOrderIndex,
    meta: {
        layout: "order",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/seller-orders/:id",
    name: "SellerOrderShow",
    component: SellerOrderShow,
    meta: {
        layout: "order",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  //SELLER Products

  {
    path: "/seller-products",
    name: "SellerProductIndex",
    component: SellerProductIndex,
    meta: {
        layout: "order",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/create-seller-product",
    name: "SellerProductcreate",
    component: SellerProductCreate,
    meta: {
        layout: "order",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-seller-product/:id",
    name: "SellerProductShow",
    component: SellerProductShow,
    meta: {
        layout: "order",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-seller-product/:id",
    name: "SellerProductedit",
    component: SellerProductEdit,
    meta: {
        layout: "order",
        requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  //   Order State
  {
    path: "/order-states",
    name: "OrderStateindex",
    component: OrderStateIndex,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/create-order-state",
    name: "OrderStatecreate",
    component: OrderStateCreate,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-order-state/:id",
    name: "OrderStateshow",
    component: OrderStateShow,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-order-state/:id",
    name: "OrderStateedit",
    component: OrderStateEdit,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  // BOUTIQUE TYPE
  {
    path: "/create-short-market-type",
    name: "ShortMarketTypeCreate",
    component: ShortMarketTypeCreate,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/short-market-types",
    name: "ShortMarketTypeIndex",
    component: ShortMarketTypeIndex,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/edit-short-market-type/:id",
    name: "ShortMarketTypeEdit",
    component: ShortMarketTypeEdit,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
  {
    path: "/show-short-market-type/:id",
    name: "ShortMarketTypeShow",
    component: ShortMarketTypeShow,
    meta: {
      layout: "default",
      requiredAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
        return;
      }else{
        next('/');
      }
    }
  },
    // BOUTIQUE TYPE
    {
      path: "/create-short-market",
      name: "ShortMarketCreate",
      component: ShortMarketCreate,
      meta: {
        layout: "default",
        requiredAuth: true
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdmin) {
          next();
          return;
        }else{
          next('/');
        }
      }
    },
    {
      path: "/short-markets",
      name: "ShortMarketIndex",
      component: ShortMarketIndex,
      meta: {
        layout: "default",
        requiredAuth: true
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdmin) {
          next();
          return;
        }else{
          next('/');
        }
      }
    },
    {
      path: "/edit-short-market/:id",
      name: "ShortMarketEdit",
      component: ShortMarketEdit,
      meta: {
        layout: "default",
        requiredAuth: true
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdmin) {
          next();
          return;
        }else{
          next('/');
        }
      }
    },
    {
      path: "/show-short-market/:id",
      name: "ShortMarketShow",
      component: ShortMarketShow,
      meta: {
        layout: "default",
        requiredAuth: true
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdmin) {
          next();
          return;
        }else{
          next('/');
        }
      }
    },



];

const router = new VueRouter({
  history: true,
  mode: "history",
  routes,

});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiredAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }else{
      next("/login");
    }
    
  } else {
    next();
  }
});

export default router;
