<template>
    <div>
        <aside class="left-sidebar">
            <div class="scroll-sidebar">
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li > <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-layout-grid2"></i><span   class="hide-menu">Configuration</span></a>
                            <ul aria-expanded="false" class="collapse">
                                <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-book"></i><span >Utilisateur</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/users">les utilisateurs</router-link></li>
                                        <li><router-link to="/create-user">Ajouter un utilisateur</router-link></li>
                                    </ul>
                                </li>
                                <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-book"></i><span >Pays</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/countries">Pays</router-link></li>
                                        <li><router-link to="/add-country">Ajouter un pays</router-link></li>
                                    </ul>
                                </li>
                                <li > <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-building"></i><span >Villes</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/cities"> les villes</router-link></li>
                                        <li ><router-link to="/add-city">Ajouter une ville</router-link></li>
                                    </ul>
                                </li>
                                <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-book"></i><span >Niveau</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/levels">les niveaux</router-link></li>
                                        <li><router-link to="/add-level">Ajouter un niveau</router-link></li>
                                    </ul>
                                </li>
                                <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-book"></i><span >Matière</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/subjects">les matières</router-link></li>
                                        <li><router-link to="/add-subject">Ajouter une matière</router-link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <!-- EDUCATION -->
                        <li > <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-layout-column3"></i><span class="hide-menu" >Education</span></a>
                            <ul aria-expanded="false" class="collapse">
                                <li><a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-graduation-cap"></i><span >Examens</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/examens">Examens</router-link></li>
                                        <li><router-link to="/examens-blanc"> Examens Blanc</router-link></li>
                                    </ul>
                                </li>
                                <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-graduation-cap"></i><span >Cours & Evaluations</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/courses">Tous les cours</router-link></li>
                                        <li><router-link to="/evaluations">Les evaluations</router-link></li>
                                    </ul>
                                </li>
                                <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-building"></i><span >Ecoles</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/schools">Toutes les ecoles</router-link></li>
                                        <li  ><router-link to="/add-school">Ajouter une ecole</router-link></li>
                                    </ul>
                                </li>
                                <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-book"></i><span >Matière</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/subjects">les matières</router-link></li>
                                        <li><router-link to="/add-subject">Ajouter une matière</router-link></li>
                                    </ul>
                                </li>
                                <li><router-link to="/actualities">Actualités</router-link></li>

                            </ul>
                        </li>
                        <!-- SHOPPING -->
                        <li > <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-shopping-cart"></i><span  class="hide-menu" >Librairie par Terre</span></a>
                            <ul aria-expanded="false" class="collapse">
                                <li><router-link to="/orders">Commandes</router-link></li>
                                <li><a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-graduation-cap"></i><span >livre</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/order-states">Etat de produit</router-link></li>
                                        <li><router-link to="/product-types">Type de produit</router-link></li>
                                        <li><router-link to="/products">Produits</router-link></li>
                                    </ul>
                                </li>
                                <li><a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-graduation-cap"></i><span >Boutique</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/short-markets">liste des articles</router-link></li>
                                        <li><router-link to="/short-market-types">Categorie d'articles</router-link></li>
                                    </ul>
                                </li>
                                <li><a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-user"></i><span >Bradeurs & Clients</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/sellers">Les Bradeurs</router-link></li>
                                        <li><router-link to="/clients">Les Client</router-link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    </div>
</template>

<script>
export default {
    data: function(){
        return {
            user:JSON.parse(this.$store.state.user)
        }
    }

}
</script>