<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Détail de type de fourniture</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">détail de type de fourniture</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="vtabs customvtab">
                                                <ul class="nav nav-tabs tabs-vertical" role="tablist">
                                                    <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#home3" role="tab"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Information</span> </a> </li>
                                                    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#profile3" role="tab"><span class="hidden-sm-up"><i class="ti-book"></i></span> <span class="hidden-xs-down">Livre</span></a> </li>
                                                </ul>
                                                <div v-if="loading">   
                                                    <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                                </div>
                                                <div v-else class="tab-content">
                                                    <div class="tab-pane active" id="home3" role="tabpanel">
                                                        <div class="p-20">
                                                            <form class="form-material" @submit.prevent="edit">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <label class="col-md-12" for="example-text">Numéro de commande
                                                                            </label>
                                                                            <div class="col-md-12">
                                                                                <input typpe="text" class="form-control" v-model="productType.order_number" name="" id="" readonly>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label class="col-md-12" for="example-text">Etat de commande
                                                                            </label>
                                                                            <div class="col-md-12">
                                                                                <input typpe="text" class="form-control" v-model="productType.order_state.label" name="" id="" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <button type="submit" class="btn btn-info">Valider la commande</button>
                                                                    <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane  p-20" id="profile3" role="tabpanel">
                                                        <div class="table-responsive">
                                                            <table v-if="productType.products.length" id="myTable" class="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Code</th>
                                                                        <th>Libelle</th>
                                                                        <th>Niveau</th>
                                                                        <th>Prix</th>
                                                                        <th>Quantité</th>
                                                                        <th>Etat</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(info,index) in productType.products" :key="index">
                                                                        <td>{{index + 1}}</td>
                                                                        <td> {{info.code}} </td>
                                                                        <td> {{info.label}} </td>
                                                                        <td> {{info.level}} </td>
                                                                        <td> {{info.price}} </td>
                                                                        <td> {{info.pivot.quantity}} </td>
                                                                        <td>
                                                                            <select class="form-control" v-model="info.pivot.state" name="" id="">
                                                                                <option v-for="(level,index) in levels" :key="index" :value="level.id">{{level.label}}</option>
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <button type="button" class="btn btn-info" @click="edit(info.pivot.id,info.pivot.state)"> Valider</button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div v-else class="alert alert-primary" role="alert">
                                                                Aucun livre enregistré
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
export default {
    data: function() {
        return {
            productType:{},
            loading: false,
            level:{},
            levels:
            [{
                id:1,
                label: 'Commandé'
             },
             {
                id:2,
                label: 'Commande validée'
             },
             {
                id:3,
                label: 'Commande refusée'
             },
            ]
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getproductType()
    },
    methods: {
        getproductType: function() {
            let app = this
            this.loading= true
                axios.get(URL_API+"order-seller/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.productType = response.data
                        this.loading= false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading= false
                    });
        },
        deleteProduct: function(id){
            axios.delete(URL_API+"product/"+id)
                .then((response) => {
                    console.log(response.data)
                    this.getproductType()
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        edit: function(id,state) {
                let app = this
                this.level.state_id = state
                axios.put(URL_API+"order-seller-state/"+id,this.level)
                .then((response) => {
                    console.log(response.data)
                    Swal.fire({
                        position: 'top-end',
                        title:'Modification effectuée!',
                        showConfirmButton: false,
                        icon: 'success',
                        timer: 1500
                    })
                    app.$router.push('/seller-orders')
                })
                .catch((error) => {
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops... !',
                        text: 'renseigne correctement les champs',
                    })
                });
        },
    },
  
}
</script>