<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Ajouter un Pays</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Ajouter un Pays</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Ajouter une Information</h5>
                                <form class="form-material" @submit.prevent="store">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Nom
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text" id="example-text" v-model="country.name" v-validate="'required|alpha'" name="libelle"  class="form-control" placeholder="ecrire ici ...">
                                                    <span class="text-danger">{{ errors.first('libelle') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Indicatif
                                                </label>
                                                <div class="col-md-12">
                                                    <input type="text" v-mask="'+(###)'" id="example-text" v-model="country.code" v-validate="'required'" name="indicatif" class="form-control" placeholder="ecrire ici ...">
                                                    <span class="text-danger">{{ errors.first('indicatif') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            country:{}
        };
    },
    components: {
        //
    },
    mounted: function() {
        
    },
    methods: {
        store: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    axios.post(URL_API+"country",this.country)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Enregistrement effectué!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/countries')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
                    
        },
    },
  
}
</script>