<template>
    <div>
        <aside class="left-sidebar">
            <div class="scroll-sidebar">
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <!-- SHOPPING -->
                        <li > <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-shopping-cart"></i><span  class="hide-menu" >Librairie par Terre</span></a>
                            <ul aria-expanded="false" class="collapse">
                                <li><router-link to="/seller-orders">Commandes</router-link></li>
                                <li><a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-graduation-cap"></i><span >Produits</span></a>
                                    <ul aria-expanded="false" class="collapse">
                                        <li><router-link to="/seller-products">Produits</router-link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    </div>
</template>

<script>
export default {
    data: function(){
        return {
        }
    }

}
</script>