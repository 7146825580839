<template>
  <div id="app">
    <div class="preloader">
        <div class="loader">
            <div class="loader__figure"></div>
            <p class="loader__label">Eglise pentecôte de Cote d'Ivoire</p>
        </div>
    </div>
    <div id="main-wrapper">
    <Header></Header>
    <SideBar></SideBar>
     <slot />
    <footer class="footer">
            Develop
    </footer>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import SideBar from '@/components/sidebar.vue'


export default {
  name: 'App',
  components: {
    Header,
    SideBar
  }
}
</script>

<style>

</style>
