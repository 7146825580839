<template>
    <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Etat</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Etat</li>
                            </ol>
                            <button type="button" class="btn btn-info d-none d-lg-block m-l-15" @click="$router.push('/create-order-state')"><i class="fa fa-plus-circle"></i> Ajouter un état</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Etat</h5>
                                <hr>
                                <div v-if="loading">   
                                    <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                </div>
                                <div v-else-if="levels.length > 0 " class="table-responsive">
                                    <table id="myTable" class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Libellés</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(level,index) in levels" :key="index">
                                                <td>{{index + 1}}</td>
                                                <td> {{level.label}} </td>
                                                <td>
                                                    <button type="button" class="btn btn-info" @click="$router.push('/show-order-state/'+level.id)"> <i class="ti-eye"></i></button>
                                                    <button type="button" class="btn btn-warning" @click="$router.push('/edit-order-state/'+level.id)"> <i class="ti-pencil"></i></button>
                                                    <button type="button" class="btn btn-danger" @click="deletelevel(level.id)"> <i class="ti-trash"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else class="alert alert-primary" role="alert">
                                    Aucun Etat
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import { VueLoading } from 'vue-loading-template'
import axios from "axios";
import { URL_API} from "@/config";
export default {
    data: function() {
        return {
            levels:[],
            loading:false
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getLevel()
    },
    methods: {
        getLevel: function() {
            let app = this
            this.loading = true
            axios.get(URL_API+"order-states")
                    .then((response) => {
                        console.log(response.data)
                        app.levels = response.data
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false
                    });
        },
        deletelevel: function(id){
            let app = this
            Swal.fire({
            title: 'Etes-vous sur vouloir supprimer?',
            text: "Cette action est irreversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Supprimer!',
            cancelButtonText: 'Annuler!'
            }).then((result) => {
                if (result.isConfirmed) {
                   axios.delete(URL_API+"order-state/"+id)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                                        position: 'top-end',
                                        title:'Suppression effectuée!',
                                        showConfirmButton: false,
                                        icon: 'success',
                                        timer: 1500
                                    })
                        app.getLevel()
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            })
            
        }
    },
  
}
</script>