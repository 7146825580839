<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Information</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Ajouter information</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Ajouter information</h5> <br>
                                <form class="form-material" @submit.prevent="store">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12 " for="example-text">Libellé
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="example-text" v-model="information.label" v-validate="'required'" name="libelle"  class="form-control" placeholder="ecrire ici ...">
                                                </div>
                                                <span class="text-danger">{{ errors.first('libelle') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Ecole
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required'" name="ecole" v-model="information.school_id" required>
                                                        <option></option>
                                                        <option v-for="(patrimoine,index) in schooles" :key="index" :value="patrimoine.id"> {{patrimoine.name}} </option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('ecole') }}</span>
                                                </div>
                                                <div class="col-md-6" v-if="information.school_id == 0">
                                                    <label class="col-md-12" for="example-text"> Lieu (autre)
                                                    </label>
                                                    <div class="col-sm-12">
                                                        <input class="form-control" v-validate="'required'" name="lieu" type="text" v-model="information.autor" id="">
                                                    </div>
                                                    <span class="text-danger">{{ errors.first('lieu') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Type d'information
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required'" name="Type" v-model="information.type" required>
                                                        <option > </option>
                                                        <option value="text">Texte</option>
                                                        <option value="select">liste</option>
                                                        <option value="number">Nombre</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('Type') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div v-if="information.type == 'text'" class="col-md-12">
                                                <label class="col-md-12" for="example-text">Texte
                                                </label>
                                                <div class="col-md-12"  >
                                                    <div id="app" style="">
                                                        <ckeditor :editor="editor" v-validate="'required'" name="Texte" v-model="information.value" :config="editorConfig" ></ckeditor>
                                                    </div>
                                                    <span class="text-danger">{{ errors.first('Texte') }}</span>
                                                </div>
                                            </div>
                                            <div v-if="information.type == 'select'" class="col-md-12">
                                                <label class="col-md-12" for="example-text">Liste
                                                </label>
                                                <!-- <div> -->
                                                    <button type="button" class="btn btn-success  float-right" @click="addGuestRow" >
                                                        <i style="color: #fff;" class="fa fa-plus" ></i>
                                                    </button>
                                                <!-- </div> -->
                                                   <br> 
                                                <div class=" form-inline col-md-12" v-for="(info, key) in inforForms" :key="key">
                                                    <input type="text" class="form-control col-md-6" v-validate="'required'" :name="'element'+key" v-model="inforForms[key]"> <br>
                                                    <span class="text-danger">{{ errors.first('element'+key) }}</span>
                                                    <button
                                                        class="btn btn-danger float-right" type="button"
                                                        @click="deleteGuestRow(key)"
                                                    >
                                                        <i style="color: #fff;" class="fa fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-if="information.type == 'number'" class="col-md-6">
                                                <label class="col-md-12" for="example-text">Nombre
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="number" id="example-text" v-validate="'required'" name="nombre" v-model="information.value"  class="form-control" placeholder="XX">
                                                    <span class="text-danger">{{ errors.first('nombre') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info" >Enregistrer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data: function() {
        return {
            information:{
                school_id: this.$route.params.id
            },
            schooles:{},
            schools:{},
            inforForms: [''],
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            }
           
        };
    },
    components: {
    },
    mounted: function() {
        this.getPatrimoines();
        this.getSchool();
    },
    methods: {
        getPatrimoines: function() {
            let app = this
                    axios.get(URL_API+"schools")
                            .then((response) => {
                                console.log(response.data)
                                app.schooles = response.data
                                app.addGuestRow(); 
                            })
                            .catch((error) => {
                                console.log(error);
                            });
        },
        getSchool: function() {
            let app = this
                axios.get(URL_API+"school/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.schools = response.data
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        },
        store: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    let app = this
                    axios.post(URL_API+"other-information",this.information)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Enregistrement effectué!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/actualities')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
                    
        },
        addGuestRow: function() {
            this.inforForms.push('');
        },
        deleteGuestRow: function(key) {
         this.inforForms.splice(key, 1);
        },

    },
  
}
</script>