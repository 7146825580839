<template>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Examen Blanc</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active">Modifier Examen Blanc</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-uppercase">Modifier Examen Blanc</h5> <br>
                                <div v-if="loading">   
                                    <vue-loading type="bars" color="#d9544e" :size="{ width: '50px', height: '50px' }"></vue-loading> 
                                </div>
                                <form v-else class="form-material" @submit.prevent="update">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12 " for="example-text">Libellé
                                                </label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="example-text" v-validate="'required'" name="libelle" v-model="information.label"  class="form-control" placeholder="ecrire ici ..." >
                                                </div>
                                                <span class="text-danger">{{ errors.first('libelle') }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Ecole
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required'" name="ecole" v-model="information.school_id" required >
                                                        <option></option>
                                                        <option v-for="(school,index) in schooles" :key="index" :value="school.id"> {{school.name}} </option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('ecole') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text">Niveau
                                                </label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" v-validate="'required'" name="Niveau" v-model="information.level" required >
                                                        <option> </option>
                                                        <option value="troisieme">Troisieme</option>
                                                        <option value="terminal">Terminal</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors.first('Niveau') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="col-md-12" for="example-text"> Année
                                                </label>
                                                <div class="col-sm-12">
                                                    <input class="form-control" v-validate="'required'" name="Annee" v-mask="'####'" type="year" v-model="information.year"  >
                                                </div>
                                                <span class="text-danger">{{ errors.first('Annee') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div  class="col-md-12">
                                                <label class="col-md-12" for="example-text">Matiere
                                                </label>
                                                <div class="col-md-12"  >
                                                    <select class="form-control" v-validate="'required'" name="matiere" v-model="information.subject">
                                                        <option value=""></option>
                                                        <option value="francais">Français</option>
                                                        <option value="anglais">Anglais</option>
                                                        <option value="mathematique">Mathematique</option>
                                                        <option value="chimie">Chimie</option>
                                                        <option value="physique">Physique</option>
                                                        <option value="geographie">Geographie</option>
                                                        <option value="histoire">Histoire</option>
                                                        <option value="philosophie">Philosophie</option>
                                                        <option value="svt">Science de la Vie et de la Terre</option>
                                                    </select>
                                                </div>
                                                <span class="text-danger">{{ errors.first('matiere') }}</span>
                                            </div>
                                            <div  class="col-md-12">
                                                <label class="col-md-12" for="example-text">Document
                                                </label>
                                                <input type="file" ref="doc" name="document" id="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-info">Enregistrer</button>
                                        <button type="submit" class="btn btn-warning float-right" @click="$router.go(-1)">Retour</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const Swal = require('sweetalert2')
import axios from "axios";
import { URL_API} from "@/config";
import { VueLoading } from 'vue-loading-template'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data: function() {
        return {
            information:{
                school_id: this.$route.params.id
            },
            schooles:{},
            schools:{},
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },
            loading : false
        };
    },
    components: {
        VueLoading
    },
    mounted: function() {
        this.getSchool();
        this.getExamen();
    },
    methods: {
        getSchool: function() {
            let app = this
            axios.get(URL_API+"schools")
            .then((response) => {
                console.log(response.data)
                app.schooles = response.data
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getExamen: function() {
                let app = this
                this.loading = true
                axios.get(URL_API+"examen-blanc/"+this.$route.params.id)
                    .then((response) => {
                        console.log(response.data)
                        app.information = response.data
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false
                    });
        },
        update: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.information.document = this.$refs.doc.files[0];
                    let app = this
                    let examen = new FormData();
                    examen.append('id', this.$route.params.id)
                    examen.append('label', this.information.label)
                    examen.append('school_id', this.information.school_id)
                    examen.append('level', this.information.level)
                    examen.append('subject', this.information.subject)
                    examen.append('year', this.information.year)
                    examen.append('document', this.information.document)

                    axios.post(URL_API+"update-examen-blanc",examen)
                    .then((response) => {
                        console.log(response.data)
                        Swal.fire({
                            position: 'top-end',
                            title:'Modification effectuée!',
                            showConfirmButton: false,
                            icon: 'success',
                            timer: 1500
                            }
                        )
                        app.$router.push('/examens-blanc')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            });
        }

    },
  
}
</script>